import { useState } from "react";
import { InAppBrowser, InAppBrowserEvent } from "@ionic-native/in-app-browser";
import { Capacitor } from "@capacitor/core";
import { useUser } from "../contexts/UserProvider";
import SurveyCompleted from "./SurveyCompleted";
import MullanyHeader from "../components/MullanyHeader";
import "../css/SurveyRedirect.css";
import { useAuth } from "../contexts/AuthProvider";
import { useTranslation } from "react-i18next";
import config from "../config-env";

const SurveyRedirect: React.FC = () => {
  const user = useUser();
  const auth = useAuth();
  const { t } = useTranslation();
  const [surveyCompleted, setSurveyCompleted] = useState(false);

  const surveyUrl = user.userData.mentoringSession.exitQuestionnaireUrl;

  const trafficLightStatus = (status: string): number => {
    switch (status) {
      case "Red":
        return 1;
      case "Amber":
        return 2;
      case "Green":
        return 3;
      default:
        return 0;
    }
  };

  const redirectToSurvey = () => {
    
    const isDesktop = Capacitor.getPlatform() == "web";
    const platform = isDesktop ? "desktop" : "app";
    console.log(`Redirecting to survey: isAuthenticated: ${auth.isAuthenticated}, platform:${platform}`);
    const url = `${surveyUrl}?platform=${platform}&status=${trafficLightStatus(
      user.userData.userDetails.status
    )}`;

    if (isDesktop) {
      window.location.replace(url);
    } else {
      const browser = InAppBrowser.create(
        url,
        "_blank",
        "location=yes,hideurlbar=yes,hidenavigationbuttons=yes"
      );

      browser.on("loadstart").subscribe((event: InAppBrowserEvent) => {
        console.log(`loadstart event fired for ${event.url}}`);
        if (event.url.includes("themullanyfund.org")) {
          console.log("closing browser")
          browser.close();
          setSurveyCompleted(true);
        }
      });
    }
  };

  if (!surveyCompleted) {
    return (
      <>
        <MullanyHeader />
        <div className="redirect-button">
          <div className="survey-message">{t("survey.redirect.message")}</div>
          <div>
            <button className="btn" onClick={redirectToSurvey}>
              {t("survey.button")}
            </button>
          </div>
        </div>
      </>
    );
  } else {
    return <SurveyCompleted />;
  }
};

export default SurveyRedirect;
