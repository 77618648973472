import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonLoading,
  IonRow,
} from "@ionic/react";

import { FormEventHandler, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ErrorNotifier } from "../components/ErrorNotifier";
import MullanyHeader from "../components/MullanyHeader";
import "../css/LoginPage.css";
import mullanyLogo from "../logos/mullany-logo.svg";
import { Authentication, useAuth } from "../contexts/AuthProvider";
import useErrorHandler from "../hooks/useErrorHandler";

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);

  const errorHandler = useErrorHandler();

  const auth: Authentication = useAuth();

  let isMounted = true;
  const { t } = useTranslation();

  interface LoginRequest {
    email: string;
    password: string;
  }

  const loginRequest: LoginRequest = { email: email, password: password };

  useEffect(() => {
    isMounted = true;
    return () => {
      setIsLoading(false);
      isMounted = false;
    };
  }, []);

  const submitLogin: FormEventHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    await auth
      .login(loginRequest)
      .catch((error) => {
        errorHandler.handleError(error);
        setIsError(true);
      })
      .finally(() => {
        if (isMounted) {
          setIsLoading(false);
        }
      });
  };

  if (isLoading) {
    return <IonLoading isOpen={true}></IonLoading>;
  }

  return (
    <>
      <MullanyHeader />
      <IonContent className="container-x">
        <div className="mullany-logo">
          <img src={mullanyLogo} />
        </div>

        <form className="login-form" onSubmit={submitLogin}>
          <div className="inputs-panel">
            <div className="input-panel">
              <i className="bi bi-envelope"></i>
              <input
                className="form-control form-rounded"
                type="text"
                placeholder={t("login.input.placeholder.email")}
                value={email}
                onInput={(e) => setEmail((e.target as HTMLInputElement).value)}
              ></input>
              <div className="form-validation-error"></div>
            </div>

            <div className="input-panel">
              <i className="bi bi-lock"></i>

              <input
                className="form-control form-rounded"
                type="password"
                placeholder={t("login.input.placeholder.password")}
                value={password}
                onInput={(e) =>
                  setPassword((e.target as HTMLInputElement).value)
                }
              ></input>
              <div className="form-validation-error"></div>
            </div>
          </div>
          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton color="mullany-blue" type="submit" expand="full">
                  {t("login.button")}
                </IonButton>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol size="6" className="ion-float-left ion-margin-top ">
                <Link
                  color="mullany"
                  to={{
                    pathname: "/passwordReset",
                  }}
                >
                  {t("login.change.password")}
                </Link>
              </IonCol>
              <IonCol size="6" className=" ion-margin-top ">
                <Link
                  color="mullany"
                  className="ion-float-right"
                  to={{
                    pathname: "/linkAccount",
                  }}
                >
                  {t("login.first.time.user.button")}
                </Link>
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </IonContent>
      <ErrorNotifier
        headerText={errorHandler.getErrorHeader()}
        bodyText={errorHandler.getErrorMessage()}
        isError={isError}
        handler={() => {
          return;
        }}
      ></ErrorNotifier>
    </>
  );
};

export default LoginPage;
