import { isAfter, parseISO, isBefore, addDays } from "date-fns";
import React, { useContext, useEffect, useRef, useState } from "react";
import Correspondent from "../entities/Correspondent";
import MentoringSession from "../entities/MentoringSession";
import UserDetails from "../entities/UserDetails";

import { IonLoading } from "@ionic/react";
import { ErrorNotifier } from "../components/ErrorNotifier";
import {
  fetchMentoringSession,
  fetchUserDetails,
  updateUserDetails as putUserDetails,
} from "../api-calls/user-api";
import useErrorHandler from "../hooks/useErrorHandler";
import { Storage } from "@capacitor/storage";
import { useAuth } from "./AuthProvider";

interface UserData {
  userDetails: UserDetails;
  mentoringSession: MentoringSession;
}

interface User {
  userData: UserData;
  getCorrespondents: (userDetails: UserDetails) => Correspondent[];
  getCorrespondentById: (
    correspondentId: string,
    userDetails: UserDetails
  ) => Correspondent;
  isRedirectToSurvey: (userData: UserData) => boolean;
  updateUserDetails: (
    deviceId: string,
    hasCompletedExitQuestionnaire: boolean,
    emailAlertOptIn: boolean
  ) => Promise<void>;
  setUserData: (userData: UserData) => void;
}

//TODO look to extract cache values to construct initial user object first

const initialData: UserData = {
  userDetails: {
    firstName: "",
    correspondents: [],
    userEmail: "",
    userId: 0,
    userType: "",
    deviceId: "",
    emailAlertOptIn: false,
    hasCompletedExitQuestionnaire: false,
    status: "",
  },

  mentoringSession: {
    adminUserId: 0,
    endDate: new Date(),
    exitQuestionnaireEndDate: "",
    exitQuestionnaireStartDate: "",
    exitQuestionnaireUrl: "",
    startDate: new Date(),
  },
};

const getUserCorrespondents = (
  userDetails: UserDetails | undefined
): Correspondent[] => {
  const adminAnnouncementCorrespondent: Correspondent = {
    userID: -2,
    userType: "admin",
    firstName: "announcements",
  };
  const adminSupportCorrespondent: Correspondent = {
    userID: -1,
    userType: "admin",
    firstName: "support.channel",
  };
  if (userDetails) {
    return [
      ...[adminAnnouncementCorrespondent, adminSupportCorrespondent],
      ...userDetails.correspondents,
    ];
  } else {
    return [adminAnnouncementCorrespondent, adminSupportCorrespondent];
  }
};

const isSurveyRedirect = (userData: UserData): boolean => {
  if (userData.userDetails && userData.userDetails.userType == "mentor") {
    return false;
  }
  const now = new Date();
  return (
    (userData.userDetails &&
      !userData.userDetails.hasCompletedExitQuestionnaire &&
      userData.mentoringSession &&
      isAfter(
        now,
        parseISO(userData.mentoringSession.exitQuestionnaireStartDate)
      ) &&
      isBefore(
        now,
        addDays(parseISO(userData.mentoringSession.exitQuestionnaireEndDate), 1)
      )) ||
    false
  );
};

const pushDeviceIdIfNew = async (userDetails: UserDetails) => {
  const { value: mullanyDeviceId } = await Storage.get({
    key: "MULLANY_DEVICE_ID",
  });

  if (mullanyDeviceId === null || mullanyDeviceId === userDetails.deviceId) {
    console.log("Device id null or already set");
    return;
  } else {
    putUserDetails(
      mullanyDeviceId,
      userDetails.hasCompletedExitQuestionnaire,
      userDetails.emailAlertOptIn
    );
  }
};

const getCorrespondentById = (
  correspondentId: string,
  userDetails: UserDetails
): Correspondent => {
  return getUserCorrespondents(userDetails).filter(
    (correspondent: Correspondent) => {
      return correspondent.userID == parseInt(correspondentId);
    }
  )[0];
};

const UserProvider: React.FC = ({ children }) => {
  const [userData, setUserData] = useState<UserData>(initialData);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const errorHandler = useErrorHandler();
  const isMounted = useRef<boolean>(true);
  const auth = useAuth();

  useEffect(() => {
    const doFetchUserData = async () => {
      try {
        const userDetails: UserDetails = await fetchUserDetails();
        pushDeviceIdIfNew(userDetails);
        const mentoringSession: MentoringSession =
          await fetchMentoringSession();
        setUserData({
          userDetails: userDetails,
          mentoringSession: mentoringSession,
        });
      } catch (error) {
        errorHandler.handleError(error);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    if (
      userData.userDetails.userId == 0 ||
      userData.mentoringSession.exitQuestionnaireUrl == ""
    ) {
      if (isMounted.current == true) {
        doFetchUserData();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const doUpdateUserDetails = async (
    deviceId: string,
    hasCompletedExitQuestionnaire: boolean,
    emailAlertOptIn: boolean
  ): Promise<void> => {
    setIsLoading(true);
    try {
      await putUserDetails(
        deviceId,
        hasCompletedExitQuestionnaire,
        emailAlertOptIn
      );
      const newUserDetails = userData.userDetails;
      newUserDetails.hasCompletedExitQuestionnaire =
        hasCompletedExitQuestionnaire;
      console.log(
        `setting new user details: ${JSON.stringify(newUserDetails)}`
      );
      setUserData({
        mentoringSession: userData.mentoringSession,
        userDetails: newUserDetails,
      });
    } catch (error) {
      errorHandler.handleError(true);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const getCorrespondents = getUserCorrespondents;
  const isRedirectToSurvey = isSurveyRedirect;
  const updateUserDetails = doUpdateUserDetails;

  if (isLoading) {
    return <IonLoading isOpen={isLoading}></IonLoading>;
  }

  if (isError) {
    return (
      <ErrorNotifier
        headerText={errorHandler.getErrorHeader()}
        bodyText={errorHandler.getErrorMessage()}
        isError={true}
        handler={auth.logout}
      />
    );
  }

  if (
    userData.userDetails.userId == 0 ||
    userData.mentoringSession.exitQuestionnaireUrl == ""
  ) {
    return null;
  } else {
    return (
      <UserContext.Provider
        value={{
          userData,
          getCorrespondents,
          getCorrespondentById,
          isRedirectToSurvey,
          updateUserDetails,
          setUserData,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
};

const UserContext = React.createContext<User>({
  userData: initialData,
  getCorrespondents: getUserCorrespondents,
  getCorrespondentById: getCorrespondentById,
  isRedirectToSurvey: isSurveyRedirect,
  updateUserDetails: putUserDetails,
  setUserData: (initialData) => console.log(""),
});

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
