import { IonLoading } from "@ionic/react";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import MullanyHeader from "../components/MullanyHeader";

import "../css/SurveyCompleted.css";
import { useUser } from "../contexts/UserProvider";
import useErrorHandler from "../hooks/useErrorHandler";
import { ErrorNotifier } from "../components/ErrorNotifier";
import { updateUserDetails } from "../api-calls/user-api";

const SurveyCompleted: React.FC = () => {
  const { t } = useTranslation();
  const isMounted = useRef<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const history = useHistory();
  const user = useUser();
  const errorHandler = useErrorHandler();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (user.userData.userDetails.hasCompletedExitQuestionnaire == true) {
      console.log("pushing to messagesDashboard");
      history.push("/messagesDashboard");
    }
  }, [user.userData]);

  const surveyCompletedClicked = async () => {
    setIsLoading(true);
    try {
      await updateUserDetails(
        user.userData.userDetails.deviceId,
        true,
        user.userData.userDetails.emailAlertOptIn
      );
      const newUserDetails = user.userData.userDetails;
      newUserDetails.hasCompletedExitQuestionnaire = true;
      console.log(
        `setting new user details: ${JSON.stringify(newUserDetails)}`
      );
      user.setUserData({
        mentoringSession: user.userData.mentoringSession,
        userDetails: newUserDetails,
      });

      console.log("updated user details");
    } catch (error) {
      setIsError(true);
      errorHandler.handleError(error);
    } finally {
      console.log("finally block");
      if (isMounted.current) {
        console.log("unloading");
        setIsLoading(false);
      }
    }
  };

  if (isLoading) {
    return <IonLoading isOpen={isLoading}></IonLoading>;
  }

  return (
    <>
      <>
        <MullanyHeader />
        <div className="survey-completed">
          <div className="survey-completed-text ion-padding-bottom">
            {t("survey.completed.thank.you.message")}
          </div>
          <div>
            <button className="btn" onClick={surveyCompletedClicked}>
              {t("survey.completed.continue")}
            </button>
          </div>
        </div>
        <ErrorNotifier
          headerText={errorHandler.getErrorHeader()}
          bodyText={errorHandler.getErrorMessage()}
          isError={isError}
        />
      </>
    </>
  );
};

export default SurveyCompleted;
