
import {version} from './version.js'

var baseUrl = process.env.REACT_APP_ENV == 'production' ? 'https://themullanyfund.org/api/v1' : 'https://mullany-api.staging.imaginet.co.uk/api/v1'
var appVersion = process.env.REACT_APP_ENV != 'production' ? version + '-s': version

var config = {
    ENV : process.env.REACT_APP_ENV,
    VERSION : appVersion,
    BASE_URL : baseUrl,
    MULLANY_API_TOKEN : process.env.REACT_APP_MULLANY_API_TOKEN,
    TOKEN_EXPIRY_OFFSET_HOURS : process.env.REACT_APP_TOKEN_EXPIRY_OFFSET_HOURS,
    CLEAR_TOKENS : process.env.REACT_APP_CLEAR_TOKENS,
    MAX_FILE_UPLOAD_SIZE : process.env.REACT_APP_MAX_FILE_UPLOAD_SIZE,
    PERMITTED_FILE_TYPES : process.env.REACT_APP_PERMITTED_FILE_TYPES,
    FIREBASE_VAPID_KEY : process.env.REACT_APP_FIREBASE_VAPID_KEY,
    FIREBASE_API_KEY : process.env.REACT_APP_FIREBASE_API_KEY,
    FIREBASE_APP_ID : process.env.REACT_APP_FIREBASE_APP_ID,
    FIREBASE_SENDER_ID : process.env.REACT_APP_FIREBASE_SENDER_ID,
    FIREBASE_MEASUREMENT_ID : process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

for (const [key, value] of Object.entries(config)) {
  if((key.startsWith("MULLANY") || key.startsWith("FIREBASE")) && value){
    console.log(`${key}:${"*".repeat(value.length)}`)
  } else {
    console.log(`${key}: ${value}`);
  }
  
}

export default config