import { Capacitor } from "@capacitor/core";
import {
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonButton,
  IonLabel,
  IonToggle,
  IonAlert,
} from "@ionic/react";

import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useAuth } from "../contexts/AuthProvider";
import { useLanguage } from "../contexts/LanguageProvider";
import "../css/MullanyHeader.css";
import mullanyLogo from "../logos/mullany-logo.svg";

export const MullanyHeader: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const language = useLanguage();
  const auth = useAuth();
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [showLogoutButton, setShowLogoutButton] = useState(false);
  const [showHomeButton, setShowHomeButton] = useState(false);
  const [showSettingsButton, setShowSettingsButton] = useState(false);
  const location = useLocation();
  const isMounted = useRef<boolean>(true);

  const doLogout = async () => {
    history.push("/logout");
  };

  const goToHome = () => {
    history.push("/");
  };

  const doConfirmLogout = () => {
    setConfirmLogout(true);
  };

  useEffect(() => {
    const determineRequiredButtonStates = async () => {
      setShowLogoutButton(auth.isAuthenticated);
      setShowHomeButton(() => {
        return true;
      });
      setShowSettingsButton(() => {
        return location.pathname != "/settings";
      });
    };
    determineRequiredButtonStates();
    return () => {
      isMounted.current = false;
    };
  }, []);

  const logoutButton = (
    <IonButton onClick={doConfirmLogout}>
      <i className="bi bi-box-arrow-right"></i>
    </IonButton>
  );
  // const homeButton = location.pathname.includes("messages/") ? (
  //   <IonButton onClick={() => history.push("/messagesDashboard")}>
  //     <i className="bi bi-house"></i>
  //   </IonButton>
  // ) : null;
  const homeButton = (
    <IonButton onClick={() => goToHome()}>
      <i className="bi bi-house"></i>
    </IonButton>
  );
  const settingsButton = (
    <IonButton
      expand="block"
      onClick={(e) => {
        e.preventDefault();
        history.push("/settings");
      }}
    >
      <i className="bi bi-gear"></i>
    </IonButton>
  );

  const mode = Capacitor.getPlatform() == "ios" ? "ios" : undefined;

  return (
    <>
      <IonHeader>
        <IonToolbar mode={mode}>
          <IonTitle>{t("mullany.header.title")}</IonTitle>
          <IonButtons slot="secondary">
            <IonLabel>{language.useWelsh() ? "cy" : "en"}</IonLabel>
            <IonToggle
              checked={language.useWelsh()}
              onIonChange={language.toggleLanguage}
            />
          </IonButtons>
          <IonButtons slot="primary" className="toolbar-buttons">
            {showHomeButton && homeButton}
            {showLogoutButton && logoutButton}
            {showSettingsButton && settingsButton}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonAlert
        isOpen={confirmLogout}
        cssClass="alert-notifier"
        onDidDismiss={() => {
          if (isMounted.current) {
            setConfirmLogout(false);
          }
        }}
        header={t("logout.confirm.header")}
        message={`<div><img src="${mullanyLogo}" class="alert-notifier-logo"></div>${t(
          "logout.confirm.message"
        )}`}
        buttons={[
          {
            text: t("cancel"),
            role: "cancel",
            cssClass: "secondary",
          },
          {
            text: t("logout"),
            handler: () => {
              doLogout();
            },
          },
        ]}
      ></IonAlert>
    </>
  );
};

export default MullanyHeader;
