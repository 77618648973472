import {
  ActionPerformed,
  PushNotificationSchema,
} from "@capacitor/push-notifications";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import useNotifications from "../hooks/useNotifications";
import { Notification } from "../hooks/useNotifications";

import { IonAlert, IonLoading } from "@ionic/react";
import { t } from "i18next";

import mullanyLogo from "../logos/mullany-logo.svg";
import { useMessages } from "../contexts/MessagesProvider";
import useErrorHandler from "../hooks/useErrorHandler";
import { ErrorNotifier } from "./ErrorNotifier";

const NotificationReceived: React.FC = () => {
  const notifications = useNotifications();
  const history = useHistory();
  const [notification, setNotification] = useState<Notification | null>(null);
  const [showNotificationAlert, setShowNotificationAlert] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [notificationSchema, setNotificationSchema] =
    useState<PushNotificationSchema>();

  const messages = useMessages();
  const errorHandler = useErrorHandler();

  const getCorrespondentIdFromNotification = (
    notification: Notification
  ): number => {
    console.log(`notification: ${notification}`);
    if (!notification.isAdmin) {
      return parseInt(notification.correspondentId);
    } else {
      if (notification.isAnnouncement) {
        return -2;
      } else {
        return -1;
      }
    }
  };

  useEffect(() => {
    const doNotificationStateChanged = async () => {
      if (notification != null) {
        const correspondentId =
          getCorrespondentIdFromNotification(notification);
        try {
          setIsLoading(true);
          console.log(`fetching messages, correspondent${correspondentId}`);
          messages.fetchMessages();
        } catch (error) {
          errorHandler.handleError(error);
          setIsError(true);
        } finally {
          setIsLoading(false);
        }

        console.log(`fetched messages, correspondent${correspondentId}`);
      }
    };

    doNotificationStateChanged();

    return () => {
      console.log(`unsetting notificationSchema`);

      setNotificationSchema(undefined);
    };
  }, [notification]);

  useEffect(() => {
    console.log("running effect in navigation for currentMessages");
    if (notification != null) {
      const correspondentId = getCorrespondentIdFromNotification(notification);
      history.push(`/messages/${correspondentId}`);
      console.log(`pushed to messages page /messages/${correspondentId}`);
    }
    return () => {
      console.log("resetting notification");
      setNotification(null);
    };
  }, [messages.currentMessages]);

  useEffect(() => {
    console.log("showNotificationAlert changed");

    return () => {
      console.log("cleaning up show notification alert");
    };
  }, [showNotificationAlert]);

  const onNotificationAction = async (
    notificationActionPerformed: ActionPerformed
  ) => {
    console.log(
      `background push notification received: ${JSON.stringify(
        notificationActionPerformed
      )}`
    );
    const { sender_id: correspondentId, announcement: isAnnouncement } =
      notificationActionPerformed.notification.data;

    setNotification({
      correspondentId: correspondentId,
      isAdmin: correspondentId == "admin",
      isAnnouncement: isAnnouncement == "true",
    });
  };

  const onNotificationReceived = async (
    notificationSchema: PushNotificationSchema
  ) => {
    console.log(
      `foreground push notification received: ${JSON.stringify(
        notificationSchema
      )}`
    );
    setShowNotificationAlert(true);
    setNotificationSchema(notificationSchema);
  };

  const onDesktopForegroundNotificationReceived = async (payload: any) => {
    console.log(`${JSON.stringify(payload)}`);
    setShowNotificationAlert(true);
    setNotificationSchema({
      id: payload.data.id,
      title: payload.data.title,
      body: payload.data.body,
      data: payload.data,
    });
  };

  notifications.provideNotificationActionListener(onNotificationAction);
  notifications.providePushNotificationReceived(onNotificationReceived);
  notifications.provideDesktopForegroundNotification(
    onDesktopForegroundNotificationReceived
  );

  if (isLoading) {
    return <IonLoading isOpen={isLoading}></IonLoading>;
  }

  if (isError) {
    <ErrorNotifier
      headerText={errorHandler.getErrorHeader()}
      bodyText={errorHandler.getErrorMessage()}
      isError={isError}
      handler={() => {
        console.log("dismiss and do");
      }}
    />;
  }

  if (showNotificationAlert && notificationSchema) {
    return (
      <IonAlert
        isOpen={showNotificationAlert}
        onDidDismiss={() => setShowNotificationAlert(false)}
        onWillDismiss={() => console.log("will dismiss...")}
        cssClass="alert-notifier"
        header={`${t("notification.received.header")} ${
          notificationSchema?.title
        }`}
        message={`<div><img src="${mullanyLogo}" class="alert-notifier-logo"></div>${notificationSchema?.body}`}
        buttons={[
          {
            text: "OK",
            role: "cancel",
            cssClass: "danger",
            handler: async () => {
              console.log("in handler");
              const {
                sender_id: correspondentId,
                announcement: isAnnouncement,
              } = notificationSchema.data;
              setNotification({
                correspondentId: correspondentId,
                isAdmin: correspondentId == "admin",
                isAnnouncement: isAnnouncement == "true",
              });
            },
          },
        ]}
      />
    );
  } else {
    return null;
  }
};

export default NotificationReceived;
