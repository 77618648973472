import "../css/NoMessagesAdvice.css";
import { useTranslation } from "react-i18next";

const NoMessagesAdvice: React.FC<{ isEditing: boolean }> = ({ isEditing }) => {
  const { t } = useTranslation();
  return isEditing ? null : (
    <div className="no-messages-advice">
      <div>{t("no.messages")}</div>
    </div>
  );
};

export default NoMessagesAdvice;
