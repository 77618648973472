import { ToggleChangeEventDetail } from "@ionic/core";
import {
  IonContent,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonItemDivider,
  IonToggle,
} from "@ionic/react";
import { format, parse } from "date-fns";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { ErrorNotifier } from "../components/ErrorNotifier";
import MullanyHeader from "../components/MullanyHeader";
import { useAuth } from "../contexts/AuthProvider";
import { useLanguage } from "../contexts/LanguageProvider";
import { useUser } from "../contexts/UserProvider";
import useErrorHandler from "../hooks/useErrorHandler";

import "../css/Settings.css";
import config from "../config-env";

const SettingsPage: React.FC = () => {
  const auth = useAuth();
  const user = useUser();
  const language = useLanguage();
  const { userDetails } = user.userData;
  const { mentoringSession } = user.userData;

  const formatSessionDate = (sessionDate: Date): string => {
    //yyyy-MM-dd -> dd-MM-yyyy
    //although typed as Date, axios will not convert without it to such without significant work in an interceptor
    const strSessionDate = sessionDate as unknown as string;
    return format(
      parse(strSessionDate, "yyyy-MM-dd", new Date()),
      "dd-MM-yyyy"
    );
  };
  const [isEmailAlertOptIn, setIsEmailAlertOptIn] = useState(
    userDetails.emailAlertOptIn
  );

  const [isError, setIsError] = useState<boolean>(false);

  const errorHandler = useErrorHandler();
  let isMounted = true;

  useEffect(() => {
    isMounted = true;
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      setIsEmailAlertOptIn(userDetails.emailAlertOptIn);
    }
  }, [userDetails]);

  const toggleEmailNotifications = async (
    event: CustomEvent<ToggleChangeEventDetail>
  ) => {
    const emailAlertOptIn = event.detail.checked;
    console.log(`updating emailAlert to ${emailAlertOptIn}`);
    try {
      await user.updateUserDetails(
        userDetails.deviceId,
        userDetails.hasCompletedExitQuestionnaire,
        emailAlertOptIn
      );
      const newUserDetails = user.userData.userDetails;
      newUserDetails.emailAlertOptIn = emailAlertOptIn;
      console.log(
        `setting emailAlertOptIn to: ${newUserDetails.emailAlertOptIn})`
      );
      user.setUserData({
        mentoringSession: user.userData.mentoringSession,
        userDetails: newUserDetails,
      });
    } catch (error) {
      errorHandler.handleError(error);
      setIsError(true);
    }
  };

  return (
    <>
      <MullanyHeader></MullanyHeader>
      <IonContent color="mullany-lilac">
        <div className="settings-content">
          {/*-- Preferences --*/}

          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>{`${t("settings.preferences")}`}</IonLabel>
            </IonItemDivider>

            <IonItem lines="none">
              <IonLabel>
                {`${t("settings.language")}`} ({language.language})
              </IonLabel>
              <IonToggle
                checked={language.useWelsh()}
                onIonChange={language.toggleLanguage}
                slot="end"
              ></IonToggle>
            </IonItem>
            {auth.isAuthenticated && (
              <IonItem lines="none">
                <IonLabel>{`${t("settings.email.notfication")}`}</IonLabel>
                <IonToggle
                  checked={isEmailAlertOptIn}
                  onIonChange={toggleEmailNotifications}
                  slot="end"
                ></IonToggle>
              </IonItem>
            )}
          </IonItemGroup>

          {/*-- Session Details --*/}

          {auth.isAuthenticated && (
            <IonItemGroup>
              <IonItemDivider>
                <IonLabel>{`${t("settings.session")}`}</IonLabel>
              </IonItemDivider>
              <IonItem lines="none">{`${t(
                "session.starts"
              )}: ${formatSessionDate(mentoringSession.startDate)}`}</IonItem>

              <IonItem lines="none">{`${t("session.ends")}: ${formatSessionDate(
                mentoringSession.endDate
              )}`}</IonItem>
            </IonItemGroup>
          )}

          {/*-- About --*/}
          <IonItemGroup>
            <IonItemDivider>
              <IonLabel>{`${t("settings.about")}`}</IonLabel>
            </IonItemDivider>

            <IonItem lines="none">{`${t("version")}: ${
              config.VERSION
            }`}</IonItem>
          </IonItemGroup>
        </div>
      </IonContent>
      <ErrorNotifier
        headerText={errorHandler.getErrorHeader()}
        bodyText={errorHandler.getErrorMessage()}
        isError={isError}
        handler={() => {
          return;
        }}
      ></ErrorNotifier>
    </>
  );
};

export default SettingsPage;
