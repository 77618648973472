import "../css/CreateMessage.css";
import TextareaAutosize from "react-textarea-autosize";
import { useTranslation } from "react-i18next";
import Correspondent from "../entities/Correspondent";
import { IonLoading } from "@ionic/react";
import { useEffect, useRef, useState } from "react";

import { Storage } from "@capacitor/storage";
import { useUser } from "../contexts/UserProvider";
import { sendMessage } from "../api-calls/messages-api";
import Message from "../entities/Message";
import emojiRegex from "emoji-regex";
import { useMessages } from "../contexts/MessagesProvider";
import useErrorHandler from "../hooks/useErrorHandler";
import { ErrorNotifier } from "./ErrorNotifier";
import SendMessageButton from "./SendMessageButton";
import CancelMessageButton from "./CancelMessageButton";

const CreateMessage: React.FC<{
  correspondent: Correspondent;
  onHeightChange: (height: number, obj: any) => void;
  //initialValue: string;
  isEditing: boolean;
  setIsEditing: (isEditing: false) => void;
  newMessageHandler: (message: Message) => Promise<void>;
}> = ({
  correspondent,
  onHeightChange,
  //initialValue,
  isEditing,
  setIsEditing,
  newMessageHandler,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const messageValueRef = useRef("");
  const messageContext = useMessages();
  const user = useUser();
  const textArea = useRef<HTMLTextAreaElement>(null);

  const regex = emojiRegex();
  const errorHandler = useErrorHandler();

  const filterOutEmojis = (bodyText: string): string => {
    let detectedEmojis = false;
    const matches = bodyText.matchAll(regex);
    const emojisToRemove = new Set<string>();

    for (const match of matches) {
      detectedEmojis = true;
      const emoji = match[0];
      emojisToRemove.add(emoji);
    }
    emojisToRemove.forEach((emoji) => {
      bodyText = bodyText.replaceAll(emoji, "");
    });
    if (detectedEmojis == true && bodyText.trim().length == 0) {
      bodyText = String.fromCodePoint(0x263a);
    }
    return bodyText;
  };

  const cacheMessageOnUnload = async () => {
    if (messageValueRef.current.length > 0) {
      await Storage.set({
        key: `composing_message_${correspondent.userID}`,
        value: messageValueRef.current.trim(),
      });
    }
  };

  const onCancelClicked = async () => {
    await cacheMessageOnUnload();
    setIsEditing(false);
  };

  const onTextAreaChanged = (event: any) => {
    setMessageValue(event.target.value);
    messageValueRef.current = event.target.value;
  };

  const resetCachedMessage = async () => {
    await Storage.remove({ key: `composing_message_${correspondent.userID}` });
  };

  const loadCachedMessage = async () => {
    const { value: cachedMessage } = await Storage.get({
      key: `composing_message_${correspondent.userID}`,
    });
    setMessageValue(cachedMessage ? cachedMessage : "");
  };

  const sendMessageErrorHandler = (error: any) => {
    errorHandler.handleError(error);
    setIsError(true);
  };

  const doSendMessage = async () => {
    let bodyText = textArea.current?.value ? textArea.current?.value : "";
    bodyText = filterOutEmojis(bodyText);
    const userIdToPost =
      correspondent.userType == "admin"
        ? "admin"
        : correspondent.userID.toString();
    const messageToPost = {
      userId: userIdToPost,
      message: bodyText,
    };
    setIsLoading(true);
    try {
      await sendMessage(messageToPost);
      //append message to messages array
      newMessageHandler(
        messageContext.createNewMessageToAppend(
          user.userData.userDetails,
          correspondent,
          bodyText,
          null
        )
      );

      setMessageValue("");
      messageValueRef.current = "";
      await resetCachedMessage();
    } catch (error) {
      sendMessageErrorHandler(error);
    } finally {
      setIsLoading(false);
      setIsEditing(false);
    }
  };

  useEffect(() => {
    const doLoadCachedMessage = async () => {
      if (isEditing == true) {
        await loadCachedMessage();
      }
    };

    const cleanUp = async () => {
      await cacheMessageOnUnload();
    };

    doLoadCachedMessage();
    return () => {
      cleanUp();
    };
  }, [isEditing]);

  if (isLoading) {
    return <IonLoading isOpen={isLoading}></IonLoading>;
  }

  return (
    <>
      <div className="create-message">
        <div className="create-message-header">
          {"..."}
          <i className="bi bi-pencil"></i>&nbsp;
          {t("create.message.header", { name: correspondent.firstName })}
        </div>
        <TextareaAutosize
          ref={textArea}
          autoFocus
          value={messageValue}
          onHeightChange={onHeightChange}
          onChange={onTextAreaChanged}
        />
        <div className="create-message-button-panel">
          <CancelMessageButton onCancelClicked={onCancelClicked} />
          <span />
          <SendMessageButton
            onSendMessageClicked={doSendMessage}
            hasMessageToSend={messageValue.length > 0}
          />
        </div>
      </div>
      <ErrorNotifier
        headerText={errorHandler.getErrorHeader()}
        bodyText={errorHandler.getErrorMessage()}
        isError={isError}
        handler={() => {
          console.log("dismiss and do");
        }}
      />
    </>
  );
};

export default CreateMessage;
