import { IonAlert, IonButton, IonContent, IonLoading } from "@ionic/react";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import MullanyHeader from "../components/MullanyHeader";
import mullanyLogo from "../logos/mullany-logo.svg";
import { SubmitHandler, useForm } from "react-hook-form";
import { ErrorNotifier } from "../components/ErrorNotifier";
import useErrorHandler from "../hooks/useErrorHandler";
import { useAuth } from "../contexts/AuthProvider";
import { useHistory } from "react-router";

const PasswordReset: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [requestSucessful, setRequestSuccessful] = useState<boolean>(false);
  const errorHandler = useErrorHandler();
  const auth = useAuth();
  const history = useHistory();
  const isMounted = useRef<boolean>(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PasswordResetRequest>();

  type PasswordResetRequest = {
    emailAddress: string;
  };

  const doPasswordReset: SubmitHandler<PasswordResetRequest> = async (
    data: PasswordResetRequest
  ) => {
    setIsLoading(true);
    try {
      await auth.passwordReset(data.emailAddress);
      setRequestSuccessful(true);
    } catch (error) {
      errorHandler.handleError(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  });

  if (isLoading) {
    return <IonLoading isOpen={isLoading}></IonLoading>;
  }

  return (
    <>
      <MullanyHeader />
      <IonContent>
        <div className="mullany-logo">
          <img src={mullanyLogo} />
        </div>
        <form className="login-form" onSubmit={handleSubmit(doPasswordReset)}>
          <div className="inputs-panel">
            <div className="input-panel">
              <i className="bi bi-envelope"></i>
              <input
                className="form-control form-rounded"
                type="text"
                placeholder={t("password.reset.placeholder.email")}
                {...register("emailAddress", {
                  required: true,
                })}
              ></input>
              <div className="form-validation-error">
                {" "}
                {errors.emailAddress ? "Email address is required" : ""}{" "}
              </div>
            </div>
            <IonButton type="submit" color="mullany-blue" expand="block">
              {t("login.password.reset")}
            </IonButton>
          </div>
        </form>
        <IonAlert
          isOpen={requestSucessful}
          onDidDismiss={() => {
            if (isMounted.current) {
              setRequestSuccessful(false);
            }
          }}
          cssClass="alert-notifier"
          header={t("reset.password.success.header")}
          message={`<div><img src="${mullanyLogo}" class="alert-notifier-logo"></div>${t(
            "reset.password.success"
          )}`}
          buttons={[
            {
              text: t("ok"),
              role: "cancel",
              cssClass: "danger",
              handler: () => {
                history.push("/login");
              },
            },
          ]}
        />
      </IonContent>

      <ErrorNotifier
        headerText={errorHandler.getErrorHeader()}
        bodyText={errorHandler.getErrorMessage()}
        isError={isError}
        handler={() => {
          return;
        }}
      ></ErrorNotifier>
    </>
  );
};

export default PasswordReset;
