import ReactDOM from "react-dom";
import { setupInterceptorsTo } from "./AxiosIntereceptors";
import axios from "axios";
import "./i18n/config";

import { AuthProvider } from "./contexts/AuthProvider";
import { App } from "./app/App";
import { LanguageProvider } from "./contexts/LanguageProvider";
import { Capacitor } from "@capacitor/core";

import Bowser from "bowser";

const redirectToAppstores = () => {
  const bowser = Bowser.parse(window.navigator.userAgent);
  console.log(
    `${bowser.browser.name}, ${bowser.os.name}, ${bowser.platform.type}`
  );
  if (!Capacitor.isNativePlatform()) {
    if (bowser.browser.name == "Safari" && bowser.os.name == "iOS") {
      //appstore
      window.location.replace(
        "https://apps.apple.com/us/app/mentora-mullany/id1615074142"
      );
    }

    if (bowser.os.name == "Android" && bowser.platform.type == "mobile") {
      //google play store
      window.location.replace(
        "https://play.google.com/store/apps/details?id=org.themullanyfund"
      );
    }
  }
};
redirectToAppstores();
setupInterceptorsTo(axios);

ReactDOM.render(
  <LanguageProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
  </LanguageProvider>,
  document.getElementById("root")
);
