import { IonContent, IonLoading } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CorrespondentSelector from "../components/CorrespondentSelector";
import { ErrorNotifier } from "../components/ErrorNotifier";
import MullanyHeader from "../components/MullanyHeader";
import { useMessages } from "../contexts/MessagesProvider";
import { useUser } from "../contexts/UserProvider";
import useErrorHandler from "../hooks/useErrorHandler";

import "../css/MessagesDashboard.css";

const MessagesDashboard: React.FC = () => {
  const user = useUser();
  const errorHandler = useErrorHandler();
  const correspondents = user.getCorrespondents(user.userData.userDetails);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const isMounted = useRef<boolean>(true);
  const messagesContext = useMessages();

  useEffect(() => {
    const doFetchMessages = async () => {
      try {
        setIsLoading(true);
        await messagesContext.fetchMessages();
      } catch (error) {
        errorHandler.handleError(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (isMounted.current) {
      doFetchMessages();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return <IonLoading isOpen={isLoading}></IonLoading>;
  }

  if (!isLoading && user.isRedirectToSurvey(user.userData)) {
    history.push("/takeSurvey"); //maybe need a cleanup?
  }

  if (messagesContext.currentMessages["0"]) {
    return <div></div>;
  }

  const correspondentSelectors =
    correspondents.length > 1 ? (
      correspondents.map((correspondent, idx) => {
        return (
          <li key={correspondent.userID} className="list-group-item">
            <CorrespondentSelector
              correspondent={correspondent}
              userId={user.userData.userDetails.userId}
              messages={messagesContext.currentMessages[correspondent.userID]}
              position={idx}
            />
          </li>
        );
      })
    ) : (
      <div></div>
    );

  return (
    <>
      <MullanyHeader />
      <IonContent color="mullany-lilac">
        <ul className="messages-dashboard list-group list-group-flush">
          <li key="-1" className="list-group-item messages-dashboard-header">
            {t("messages.dashboard.header")}
          </li>
          {correspondentSelectors}
        </ul>
      </IonContent>
      <ErrorNotifier
        headerText={errorHandler.getErrorHeader()}
        bodyText={errorHandler.getErrorMessage()}
        isError={isError}
        handler={() => {
          console.log("dismiss and do");
        }}
      />
    </>
  );
};

export default MessagesDashboard;
