const CancelMessageButton: React.FC<{
  onCancelClicked: () => void;
}> = ({ onCancelClicked }) => {
  return (
    <button onClick={onCancelClicked} className="btn btn-rounded btn-danger">
      <i className="bi bi-x"></i>
    </button>
  );
};

export default CancelMessageButton;
