import Token from "../entities/Token";
import { Storage } from "@capacitor/storage";
import { add, formatISO, isAfter, parseISO } from "date-fns";

export const MULLANY_FUND_BEARER_TOKEN = "MULLANY_FUND_BEARER_TOKEN";
const MULLANY_FUND_BEARER_TOKEN_EXPIRES_AT =
  "MULLANY_FUND_BEARER_TOKEN_EXPIRES_AT";
export const MULLANY_FUND_REFRESH_TOKEN = "MULLANY_FUND_REFRESH_TOKEN";
const MULLANY_FUND_REFRESH_TOKEN_EXPIRES_AT =
  "MULLANY_FUND_REFRESH_TOKEN_EXPIRES_AT";

export const storeTokens = async (
  accessToken: Token,
  refreshToken: Token
): Promise<void> => {
  await Storage.set({
    key: MULLANY_FUND_BEARER_TOKEN,
    value: accessToken.token,
  });

  await Storage.set({
    key: MULLANY_FUND_REFRESH_TOKEN,
    value: refreshToken.token,
  });

  await Storage.set({
    key: MULLANY_FUND_BEARER_TOKEN_EXPIRES_AT,
    value: getExpiryDateForToken(accessToken),
  });

  await Storage.set({
    key: MULLANY_FUND_REFRESH_TOKEN_EXPIRES_AT,
    value: getExpiryDateForToken(refreshToken),
  });
};

export const getExpiryDateForToken = (token: Token): string => {
  const expiryDate: Date = add(new Date(), {
    seconds: token.expires_in,
  });
  return formatISO(expiryDate);
};

export const clearTokens = async (): Promise<void> => {
  await Storage.remove({ key: MULLANY_FUND_BEARER_TOKEN });
  await Storage.remove({ key: MULLANY_FUND_REFRESH_TOKEN });
  await Storage.remove({ key: MULLANY_FUND_BEARER_TOKEN_EXPIRES_AT });
  await Storage.remove({ key: MULLANY_FUND_REFRESH_TOKEN_EXPIRES_AT });
};

export const hasActiveToken = async (): Promise<boolean> => {
  const { value: bearerToken } = await Storage.get({
    key: MULLANY_FUND_BEARER_TOKEN,
  });

  if (bearerToken == null) {
    return false;
  }

  const activeToken = !(await tokenHasExpired(
    new Date(),
    MULLANY_FUND_BEARER_TOKEN
  ));
  console.log(`has active token: ${activeToken}`);
  return activeToken;
};

export const tokenHasExpired = async (
  currentDate: Date,
  token: string
): Promise<boolean> => {
  const { value: expiryDateString } = await Storage.get({
    key: token + "_EXPIRES_AT",
  });
  if (!expiryDateString) {
    return true;
  }

  const expiryDate = parseISO(expiryDateString);
  console.log(`found bearer token expires at ${expiryDateString}`);
  return isAfter(currentDate, expiryDate);
};
