import { AxiosInstance, AxiosRequestConfig } from "axios";
import { GetResult, Storage } from "@capacitor/storage";
import config from "./config-env";

const onRequest = async (
  axiosRequestConfig: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  axiosRequestConfig.headers = headers;
  axiosRequestConfig.timeout = 10000;

  const params = axiosRequestConfig.params || {};
  axiosRequestConfig.params = { ...params, token: config.MULLANY_API_TOKEN };

  if (
    axiosRequestConfig.url === `${config.BASE_URL}/login` ||
    axiosRequestConfig.url === `${config.BASE_URL}/studentRegister` ||
    axiosRequestConfig.url === `${config.BASE_URL}/forgottenPassword`
  ) {
    return axiosRequestConfig;
  } else {
    //send bearer token
    //if we are hitting the refresh endpoint, we use the refresh token
    let bearerToken;
    if (axiosRequestConfig.url === `${config.BASE_URL}/refresh`) {
      //bearerToken = localStorage.getItem("MULLANY_FUND_REFRESH_TOKEN");
      const result: GetResult = await Storage.get({
        key: "MULLANY_FUND_REFRESH_TOKEN",
      });
      bearerToken = result.value;
      console.log("Using existing refresh token as bearer");
    } else {
      //bearerToken = localStorage.getItem("MULLANY_FUND_BEARER_TOKEN");
      const result: GetResult = await Storage.get({
        key: "MULLANY_FUND_BEARER_TOKEN",
      });
      bearerToken = result.value;
    }
    Object.assign(axiosRequestConfig.headers, {
      Authorization: "Bearer " + bearerToken,
    });
    return axiosRequestConfig;
  }
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance
): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest);
  return axiosInstance;
}
