/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "bootstrap/dist/css/bootstrap.min.css";
import "../theme/variables.css";

import AuthenticatedApp from "./AuthenticatedApp";
import { useAuth } from "../contexts/AuthProvider";
import { UserProvider } from "../contexts/UserProvider";
import UnauthenticatedApp from "./UnauthenticatedApp";
import { useEffect, useState } from "react";
import { IonAlert, IonLoading } from "@ionic/react";

import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

import useNotifications from "../hooks/useNotifications";
import { Capacitor } from "@capacitor/core";
import { t } from "i18next";
import mullanyLogo from "../logos/mullany-logo.svg";

const App: React.FC = () => {
  const auth = useAuth();

  const [isReady, setIsReady] = useState(false);
  const [showPermissionsButton, setShowPermissionsButton] = useState(false);

  const notifications = useNotifications();

  useEffect(() => {
    const checkAuthenticated = async () => {
      if (!auth.isAuthenticated) {
        //tryRefreshTokens();
        await auth
          .refresh()
          .catch(() => console.log("Did not refresh token"))
          .finally(async () => {
            setIsReady(true);
          });
      } else {
        setIsReady(true);
      }
    };
    checkAuthenticated();
  }, []);

  useEffect(() => {
    if (Capacitor.isPluginAvailable("PushNotifications")) {
      notifications.checkNotificationPermissions();
    } else {
      if (Notification && Notification.permission == "granted") {
        notifications.initialiseForDesktop();
      } else if (Notification && Notification.permission == "default") {
        setShowPermissionsButton(true);
      }
    }
  }, []);

  useEffect(() => {
    createAuthRefreshInterceptor(axios, auth.invalidCredentialsHandler, {
      statusCodes: [400],
    });
  }, []);

  if (!isReady) {
    return <IonLoading isOpen={true}></IonLoading>;
  }

  if (showPermissionsButton) {
    return (
      <IonAlert
        isOpen={showPermissionsButton}
        onDidDismiss={() => setShowPermissionsButton(false)}
        cssClass="alert-notifier"
        header={t("notification.permission.header")}
        message={`<div><img src="${mullanyLogo}" class="alert-notifier-logo"></div>${t(
          "notification.permission.message"
        )}`}
        buttons={[
          {
            text: "OK",
            role: "cancel",
            cssClass: "danger",
            handler: () => {
              notifications.initialiseForDesktop();
            },
          },
        ]}
      />
    );
  } else {
    return auth.isAuthenticated ? (
      <UserProvider>
        <AuthenticatedApp />
      </UserProvider>
    ) : (
      <UnauthenticatedApp />
    );
  }
};

export { App };
