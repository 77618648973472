import { t } from "i18next";
import React, { Component, ErrorInfo, ReactNode } from "react";
import { ErrorNotifier } from "./ErrorNotifier";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  headerText: string;
  bodyText: string;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    headerText: "",
    bodyText: "",
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, headerText: t("error"), bodyText: _.message };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      console.log("returning error notifier from error boundary");
      return (
        <ErrorNotifier
          headerText={this.state.headerText}
          bodyText={this.state.bodyText}
          isError={true}
        ></ErrorNotifier>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
