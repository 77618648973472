import { IonLoading } from "@ionic/react";
import { useState } from "react";
import useErrorHandler from "../hooks/useErrorHandler";

import { ErrorNotifier } from "./ErrorNotifier";
import "../css/CreateMessageButton.css";
import { useTranslation } from "react-i18next";

const CreateMessageButton: React.FC<{
  onButtonClicked: () => void;
}> = ({ onButtonClicked }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const errorHandler = useErrorHandler();

  if (isLoading) {
    return <IonLoading isOpen={isLoading}></IonLoading>;
  }

  if (isError) {
    return (
      <ErrorNotifier
        headerText={errorHandler.getErrorHeader()}
        bodyText={errorHandler.getErrorMessage()}
        isError={isError}
        handler={() => {
          console.log("error, no op");
        }}
      />
    );
  }

  return (
    <>
      <button
        type="button"
        className="send-button btn btn-rounded"
        onClick={onButtonClicked}
      >
        <i className="bi bi-pencil"></i>
      </button>
    </>
  );
};

export default CreateMessageButton;
