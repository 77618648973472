const SendMessageButton: React.FC<{
  hasMessageToSend: boolean;
  onSendMessageClicked: () => void;
}> = ({ hasMessageToSend, onSendMessageClicked }) => {
  return (
    <button
      className="btn btn-rounded btn-success"
      disabled={!hasMessageToSend}
      onClick={onSendMessageClicked}
    >
      <i className="bi bi-send"></i>
    </button>
  );
};

export default SendMessageButton;
