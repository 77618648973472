import Correspondent from "../entities/Correspondent";
import mullanyLogo from "../logos/mullany-logo.svg";
import infoLogo from "../logos/info-circle-fill.svg";

const DashboardMullanyAvatar: React.FC<{ correspondent: Correspondent }> = ({
  correspondent,
}) => {
  return correspondent.userID == -2 ? (
    <div className="avatar odd">
      <i className="bi bi-info-lg"></i>
    </div>
  ) : (
    <div className="avatar-mullany">
      <img src={mullanyLogo} />
    </div>
  );
};

export default DashboardMullanyAvatar;
