import { useHistory } from "react-router";
import DashboardAvatar from "./DashboardAvatar";
import DashboardMullanyAvatar from "./DashboardMullanyAvatar";

import Correspondent from "../entities/Correspondent";
import Message from "../entities/Message";

import { Storage } from "@capacitor/storage";
import { useEffect, useState } from "react";
import "../css/CorrespondentSelector.css";
import { useTranslation } from "react-i18next";

const CorrespondentSelector: React.FC<{
  correspondent: Correspondent;
  userId: number;
  messages: Message[];
  position: number;
}> = ({ correspondent, userId, messages, position }) => {
  const history = useHistory();
  const [newMessageCount, setNewMessageCount] = useState(0);
  const { t } = useTranslation();

  const navigateToMessages = (correspondentId: number) => {
    history.push(`/messages/${correspondentId}`, { messages });
  };

  useEffect(() => {
    const filterForTheirMessages = (messages: Message[]) => {
      return messages.filter((message: Message) => {
        return message.sent_by.id != userId;
      });
    };

    const getNewMessagesCount = async (
      messages: Message[],
      correspondent: Correspondent
    ): Promise<void> => {
      const { value: storedMessages } = await Storage.get({
        key: `messages_${correspondent.userID}`,
      });

      if (storedMessages) {
        const storedMessagesObject = JSON.parse(storedMessages);
        console.log(`Found ${storedMessagesObject.length} stored messages`);

        console.log(
          `Found ${storedMessagesObject.length} stored messages from others`
        );
        setNewMessageCount(
          filterForTheirMessages(messages).length -
            filterForTheirMessages(storedMessagesObject).length
        );
      } else {
        return setNewMessageCount(messages.length);
      }
    };
    getNewMessagesCount(messages, correspondent);
  }, []);

  const correspondentFirstName =
    correspondent.userType == "admin"
      ? t(correspondent.firstName)
      : correspondent.firstName;

  return (
    <>
      <div
        className="correspondent-selector"
        onClick={() => navigateToMessages(correspondent.userID)}
      >
        {position < 2 ? (
          <DashboardMullanyAvatar correspondent={correspondent} />
        ) : (
          <DashboardAvatar isOdd={position % 2 == 1} />
        )}
        <span>
          <h5>{correspondentFirstName}</h5>
          <div>
            {`${messages.length} ${
              messages.length == 1 ? t("message") : t("messages")
            }`}
          </div>
        </span>
        <span className="new-message-count">
          <div>
            <h5>&nbsp;</h5>
            {newMessageCount > 0 ? (
              <span>
                {newMessageCount == 1
                  ? ` ${newMessageCount} ${t("new.message")}`
                  : ` ${newMessageCount} ${t("new.messages")}`}
              </span>
            ) : null}
          </div>
        </span>
      </div>
    </>
  );
};

export default CorrespondentSelector;
