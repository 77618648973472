import axios, { AxiosResponse } from "axios";
import _ from "lodash";
import config from "../config-env";
import Correspondent from "../entities/Correspondent";
import Message from "../entities/Message";

export interface PostMessage {
  userId: string;
  message: string;
}

export interface DownloadedAttachment {
  fileName: string;
  file: string;
}

const filterOutUnmoderatedMessages = (
  messages: Message[],
  userId: number
): Message[] => {
  return messages.filter((message: Message) => {
    const shouldReject =
      ((message.moderation == "PENDING" || message.moderation == "DELETED") &&
        message.sent_to.id == userId) ||
      typeof message.message == "boolean";
    if (shouldReject) {
      console.log("");
    }
    return !shouldReject;
  });
};

const fetchMessages = async (
  correspondents: Correspondent[],
  userId: number
): Promise<Message[][]> => {
  const allMessages: Message[][] = new Array<Message[]>();
  let messageRetrievalError;

  console.log(`Getting messages for ${correspondents.length} correspondents`);
  for (const correspondent of correspondents) {
    //only get admin messages once, although we have 2 admin correspondents!
    if (correspondent.userID == -2) {
      continue;
    }

    try {
      const correspondentId =
        correspondent.userType == "admin" ? "admin" : correspondent.userID;
      const response: AxiosResponse<Message[]> = await axios.get(
        `${config.BASE_URL}/message?userId=${correspondentId}`
      );
      allMessages.push(filterOutUnmoderatedMessages(response.data, userId));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          allMessages.push(new Array<Message>());
        } else {
          messageRetrievalError = error;
        }
      } else {
        messageRetrievalError = error;
      }
    }
  }

  if (messageRetrievalError) {
    return Promise.reject({ error: messageRetrievalError, step: "messages" });
  } else {
    return Promise.resolve(allMessages);
  }
};

const fetchMessagesForCorrespondentId = async (
  correspondentId: string,
  userId: number
): Promise<Message[]> => {
  try {
    const response: AxiosResponse<Message[]> = await axios.get(
      `${config.BASE_URL}/message?userId=${correspondentId}`
    );
    return Promise.resolve(filterOutUnmoderatedMessages(response.data, userId));
  } catch (error) {
    return Promise.reject({ error: error, step: "messages" });
  }
};

const partitionAdminMessages = (adminMessages: Message[]): Message[][] => {
  return _.partition(
    adminMessages,
    (message: Message) => message.admin_status == "ANNOUNCEMENT"
  );
};

const sendMessage = async (messageToPost: PostMessage): Promise<void> => {
  try {
    await axios.post(`${config.BASE_URL}/message`, messageToPost);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject({ error: error, step: "sendMessage" });
  }
};

const sendAttachment = async (formData: FormData): Promise<void> => {
  try {
    await axios.post(`${config.BASE_URL}/message/attachment`, formData);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject({ error: error, step: "sendAttachment" });
  }
};

const getAttachment = async (
  fileToken: string
): Promise<DownloadedAttachment> => {
  try {
    const response: AxiosResponse<DownloadedAttachment> = await axios.get(
      `${config.BASE_URL}/document/${fileToken}`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject({ error: error, step: "getAttachment" });
  }
};

export {
  fetchMessages,
  fetchMessagesForCorrespondentId,
  sendMessage,
  sendAttachment,
  getAttachment,
  partitionAdminMessages,
};
