import axios, { AxiosResponse } from "axios";
import config from "../config-env";
import MentoringSession from "../entities/MentoringSession";
import UserDetails from "../entities/UserDetails";

const fetchUserDetails = async (): Promise<UserDetails> => {
  try {
    const response: AxiosResponse<UserDetails> = await axios.get(
      `${config.BASE_URL}/userDetails`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject({ error: error, step: "userDetails" });
  }
};

const fetchMentoringSession = async (): Promise<MentoringSession> => {
  try {
    const response: AxiosResponse<MentoringSession> = await axios.get(
      `${config.BASE_URL}/mentoringSession`
    );
    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject({ error: error, step: "mentoringSession" });
  }
};

const updateUserDetails = async (
  deviceId: string,
  hasCompletedExitQuestionnaire: boolean,
  emailAlertOptIn: boolean
): Promise<void> => {
  const payload = {
    hasCompletedExitQuestionnaire: hasCompletedExitQuestionnaire,
    deviceId: deviceId,
    emailAlertOptIn: emailAlertOptIn,
  };
  try {
    await axios.put(`${config.BASE_URL}/userDetails`, payload);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject({ error: error, step: "updateUserDetails" });
  }
};

export { fetchUserDetails, fetchMentoringSession, updateUserDetails };
