import { t } from "i18next";
import _ from "lodash";
import { useRef, BaseSyntheticEvent, useState } from "react";
import { sendAttachment } from "../api-calls/messages-api";
import config from "../config-env";
import { useMessages } from "../contexts/MessagesProvider";
import { useUser } from "../contexts/UserProvider";
import Correspondent from "../entities/Correspondent";
import Message from "../entities/Message";
import useErrorHandler from "../hooks/useErrorHandler";
import AttachmentConfirmation from "./AttachmentConfirmation";
import { ErrorNotifier } from "./ErrorNotifier";

const AttachmentButton: React.FC<{
  correspondent: Correspondent;
  newMessageHandler: (message: Message) => void;
}> = ({ correspondent, newMessageHandler }) => {
  const fileInput = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isPermittedFileType, setIsPermittedFileType] = useState(true);
  const [isPermittedFileSize, setIsPermittedFileSize] = useState(true);
  const [invalidFileErrorHeader, setInvalidFileErrorHeader] = useState("");
  const [invalidFileErrorText, setInvalidFileErrorText] = useState("");
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File>();
  const errorHandler = useErrorHandler();
  const user = useUser();
  const messageContext = useMessages();

  const checkPermittedFileSize = (selectedFile: File): boolean => {
    const isPermitted =
      selectedFile.size <= parseInt(config.MAX_FILE_UPLOAD_SIZE as string);
    setIsPermittedFileSize(isPermitted);
    if (!isPermitted) {
      setInvalidFileErrorHeader(t("error.message.attachment.file.size.header"));
      setInvalidFileErrorText(t("error.message.attachment.file.size"));
    }
    return isPermitted;
  };

  const checkPermittedFileType = (selectedFile: File): boolean => {
    const permittedFileTypes: Array<string> = (
      config.PERMITTED_FILE_TYPES as string
    ).split(",");
    const splitFileName: Array<string> = selectedFile.name.split(".");
    if (splitFileName.length > 1) {
      const selectedFileType = splitFileName.pop();
      const isPermitted = _.includes(
        permittedFileTypes,
        selectedFileType?.toLowerCase()
      );
      setIsPermittedFileType(isPermitted);
      if (!isPermitted) {
        setInvalidFileErrorHeader(
          t("error.message.attachment.file.type.header")
        );
        setInvalidFileErrorText(t("error.message.attachment.file.type"));
      }
      return isPermitted;
    } else {
      setIsPermittedFileType(false);
      return false;
    }
  };

  const confirmFileUpload = (event: BaseSyntheticEvent) => {
    if (
      fileInput.current &&
      fileInput.current.files != null &&
      fileInput.current.files.length > 0
    ) {
      const selectedFile: File = fileInput.current.files[0];
      setSelectedFile(selectedFile);

      if (!checkPermittedFileType(selectedFile)) {
        return;
      }
      if (!checkPermittedFileSize(selectedFile)) {
        return;
      }
      setShowConfirmAlert(true);
    } else {
      return;
    }
  };

  const doFileUpload = async (selectedFile: File | undefined) => {
    if (!selectedFile) {
      return;
    }
    setIsLoading(true);
    const formData: FormData = new FormData();
    formData.append("userId", correspondent.userID.toString());
    formData.append("attachment", selectedFile);
    try {
      await sendAttachment(formData);
      newMessageHandler(
        messageContext.createNewMessageToAppend(
          user.userData.userDetails,
          correspondent,
          null,
          selectedFile.name
        )
      );
    } catch (error) {
      errorHandler.handleError(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
      if (fileInput.current) {
        fileInput.current.value = "";
      }
    }
  };

  const uploadCancelled = (selectedFile: File | undefined) => {
    if (fileInput.current) {
      fileInput.current.value = "";
    }
  };

  if (!isPermittedFileType || !isPermittedFileSize) {
    return (
      <ErrorNotifier
        headerText={invalidFileErrorHeader}
        bodyText={invalidFileErrorText}
        handler={() => {
          setIsPermittedFileSize(true);
          setIsPermittedFileType(true);
          setInvalidFileErrorHeader("");
          setInvalidFileErrorText("");
        }}
        isError={true}
      />
    );
  }

  return (
    <>
      <label className="attachment-button btn btn-rounded" htmlFor="file-input">
        <i className="bi bi-paperclip"></i>
      </label>
      <input
        ref={fileInput}
        type="file"
        id="file-input"
        accept=".docx,.doc,.pdf,.txt"
        onChange={confirmFileUpload}
      />
      <AttachmentConfirmation
        showAlert={showConfirmAlert}
        selectedFile={selectedFile}
        onConfirm={() => doFileUpload(selectedFile)}
        onCancel={() => uploadCancelled(selectedFile)}
        setShowAlert={setShowConfirmAlert}
      />
      <ErrorNotifier
        headerText={errorHandler.getErrorHeader()}
        bodyText={errorHandler.getErrorMessage()}
        isError={isError}
      />
    </>
  );
};

export default AttachmentButton;
