import { Storage } from "@capacitor/storage";
import { ToggleChangeEventDetail } from "@ionic/core";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import i18n from "../i18n/config";

interface Language {
  toggleLanguage: (event: CustomEvent<ToggleChangeEventDetail>) => void;
  useWelsh: () => boolean;
  language: string;
}

const LanguageProvider: React.FC = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState("");

  useEffect(() => {
    const doInitialiseLanguage = async () => {
      const { value: lang } = await Storage.get({ key: "lang" });
      if (lang != null) {
        i18n.changeLanguage(lang);
        setSelectedLanguage(lang);
      }
    };
    doInitialiseLanguage();
  }, []);

  const doToggleLanguage = async (
    event: CustomEvent<ToggleChangeEventDetail>
  ): Promise<void> => {
    //on for Welsh, off for English
    const lang = event.detail.checked ? "cy" : "en";
    await Storage.set({ key: "lang", value: lang });
    i18n.changeLanguage(lang);
    setSelectedLanguage(lang);

    console.log(i18n.language);
  };

  const getLanguage = () => {
    //return i18n.language;
    return selectedLanguage;
  };

  const useWelsh = (): boolean => {
    return i18n.language == "cy";
  };

  return (
    <LanguageContext.Provider
      value={{
        toggleLanguage: doToggleLanguage,
        useWelsh: useWelsh,
        language: getLanguage(),
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
const LanguageContext = React.createContext<Language>({
  toggleLanguage: () => {
    console.log("");
  },
  useWelsh: () => {
    return false;
  },
  language: "cy",
});
const useLanguage = () => useContext(LanguageContext);

export { LanguageProvider, useLanguage };
