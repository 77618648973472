import Message from "../entities/Message";
import "../css/ChatMessage.css";
import AttachmentMessage from "./AttachmentMessage";

import linkifyHtml from "linkifyjs/html";

const ChatMessage: React.FC<{ message: Message; isMyMessage: boolean }> = ({
  message,
  isMyMessage,
}) => {
  const ensureLineBreaks = (messageText: string): string => {
    return messageText.replace(/(?:\r\n|\r|\n)/g, "<br />");
  };

  if (message.attachment) {
    return <AttachmentMessage message={message} isMyMessage={isMyMessage} />;
  } else {
    if (typeof message.message == "boolean") {
      return null;
    }
    return (
      <div
        className="chat-message"
        dangerouslySetInnerHTML={{
          __html: linkifyHtml(ensureLineBreaks(message.message)),
        }}
      ></div>
    );
  }
};

export default ChatMessage;
