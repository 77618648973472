import {
  IonDatetime,
  IonButton,
  IonLoading,
  IonContent,
  IonAlert,
} from "@ionic/react";

import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ErrorNotifier } from "../components/ErrorNotifier";
import MullanyHeader from "../components/MullanyHeader";
import { useEffect, useRef, useState } from "react";
import mullanyLogo from "../logos/mullany-logo.svg";
import { format, parse, parseISO } from "date-fns";
import { AccountRegistration, registerAccount } from "../api-calls/login-api";
import useErrorHandler from "../hooks/useErrorHandler";

import "../css/RegisterAccount.css";
import { useHistory } from "react-router";

const RegisterAccount: React.FC = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm<CreatePasswordInputs>();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);
  const errorHandler = useErrorHandler();
  const history = useHistory();
  const isMounted = useRef<boolean>(true);
  const datepicker = useRef<HTMLIonDatetimeElement>(null);
  const dobInput = useRef<HTMLInputElement | null>(null);

  type CreatePasswordInputs = {
    emailAddress: string;
    dob: string;
    password: string;
    confirmPassword: string;
  };

  const { t } = useTranslation();
  const passwordValue = watch("password");

  const validateConfirmPassword = (value: string) => {
    return value == passwordValue;
  };
  const validateEmailAddress = (value: string) => {
    return value.includes("@");
  };
  const validatePassword = (value: string) => {
    const lengthTest = value.length >= 8;
    const lowercaseTest = /[a-z]/.test(value);
    const upperCaseTest = /[A-Z]/.test(value);
    const digitTest = /[\d]/.test(value);
    const nonAlphaNumericTest = /\W/.test(value);
    return (
      lengthTest &&
      lowercaseTest &&
      upperCaseTest &&
      digitTest &&
      nonAlphaNumericTest
    );
  };

  const getPasswordValidationFailureMessage = () => {
    if (errors.password?.type == "validate") {
      return t("register.format.password");
    }
    if (errors.password?.type == "required") {
      return t("register.required.password");
    }
  };

  const getEmailValidationFailureMessage = () => {
    if (errors.emailAddress?.type == "validate") {
      return t("register.format.email");
    }
    if (errors.emailAddress?.type == "required") {
      return t("register.required.email");
    }
  };

  const presentationDateFormat = "dd/MM/yyyy";
  const [dobValue, setDobValue] = useState("");

  const dobChanged = (event: CustomEvent) => {
    // set the input field to display the date;
    // the datetime component below the form usually displays it, but we hide it.
    if (errors.dob) {
      clearErrors("dob");
    }
    //TODO couldn't get dob field to be managed by useForm.  Need to share a ref; gave up.
    if (dobInput.current) {
      dobInput.current.value = format(
        parseISO(event.detail.value),
        presentationDateFormat
      );
      setDobValue(dobInput.current.value);
    }
  };

  const onSubmit: SubmitHandler<CreatePasswordInputs> = async (data) => {
    const account: AccountRegistration = {
      email: data.emailAddress,
      dob: format(
        parse(dobValue, presentationDateFormat, new Date()),
        "yyyy-MM-dd"
      ),
      password: data.password,
    };

    setIsLoading(true);
    try {
      await registerAccount(account);
      setIsRegistered(true);
    } catch (error) {
      errorHandler.handleError(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return <IonLoading isOpen={isLoading}></IonLoading>;
  }

  return (
    <>
      <MullanyHeader />
      <IonContent>
        <div className="mullany-logo">
          <img src={mullanyLogo} />
        </div>

        <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="inputs-panel">
            <div className="register-account-input-panel">
              <i className="bi bi-calendar-check"></i>
              <input
                className="form-control form-rounded"
                type="text"
                placeholder={t("register.input.placeholder.dob")}
                onFocus={() => {
                  datepicker.current?.open();
                }}
                ref={dobInput}
              ></input>
              <div className="form-validation-error-register-account">
                {errors.dob ? t("register.required.dob") : ""}
              </div>
            </div>
            <div className="register-account-input-panel">
              <i className="bi bi-envelope"></i>
              <input
                className="form-control form-rounded"
                type="text"
                placeholder={t("register.input.placeholder.email")}
                {...register("emailAddress", {
                  required: true,
                  validate: validateEmailAddress,
                })}
                onChange={() => clearErrors("emailAddress")}
              ></input>
              <div className="form-validation-error-register-account">
                {errors.emailAddress ? getEmailValidationFailureMessage() : ""}
              </div>
            </div>

            <div className="register-account-input-panel">
              <i className="bi bi-lock"></i>
              <input
                className="form-control form-rounded"
                type="password"
                placeholder={t("register.input.placeholder.password")}
                {...register("password", {
                  required: true,
                  validate: validatePassword,
                })}
                onChange={() => clearErrors("password")}
              ></input>

              <div className="form-validation-error-register-account">
                {errors.password ? getPasswordValidationFailureMessage() : ""}
              </div>
            </div>
            <div className="register-account-input-panel">
              <i className="bi bi-lock"></i>
              <input
                className="form-control form-rounded"
                type="password"
                placeholder={t("register.input.placeholder.confirm")}
                {...register("confirmPassword", {
                  validate: validateConfirmPassword,
                })}
                onChange={() => clearErrors("confirmPassword")}
              ></input>

              <div className="form-validation-error-register-account">
                {errors.confirmPassword
                  ? t("register.confirm.password.mismatch")
                  : ""}
              </div>
            </div>

            <IonButton
              disabled={dobValue == ""}
              type="submit"
              color="mullany-blue"
              expand="block"
            >
              {t("login.link.account")}
            </IonButton>
          </div>
          <IonDatetime
            monthShortNames={t("month.short.names")}
            className="ion-hide"
            mode="ios"
            onIonChange={dobChanged}
            ref={datepicker}
          ></IonDatetime>
        </form>
      </IonContent>
      <IonAlert
        isOpen={isRegistered}
        onDidDismiss={() => {
          if (isMounted.current) {
            setIsRegistered(false);
          }
        }}
        cssClass="alert-notifier"
        header={t("account.created.header")}
        message={`<div><img src="${mullanyLogo}" class="alert-notifier-logo"></div>${t(
          "account.created.message"
        )}`}
        buttons={[
          {
            text: t("ok"),
            role: "cancel",
            cssClass: "danger",
            handler: () => {
              history.push("/login");
            },
          },
        ]}
      />
      <ErrorNotifier
        headerText={errorHandler.getErrorHeader()}
        bodyText={errorHandler.getErrorMessage()}
        isError={isError}
        handler={() => {
          return;
        }}
      ></ErrorNotifier>
    </>
  );
};

export default RegisterAccount;
