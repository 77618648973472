import { format, parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import Correspondent from "../entities/Correspondent";
import Message from "../entities/Message";

const ChatMessageHeader: React.FC<{
  correspondent: Correspondent;
  message: Message;
}> = ({ correspondent, message }) => {
  const { t } = useTranslation();

  const isCorrespondentMessage =
    message.sent_by.id == correspondent.userID ||
    message.sent_by.type == "admin";
  const messageTimeFormat = "dd/MM/yyyy@HH:mm";

  const isMessageRead = typeof message.message_read != "boolean";
  const messageStatusIndicator = isMessageRead ? (
    <i className="bi bi-envelope-open"></i>
  ) : (
    <i className="bi bi-envelope"></i>
  );

  const correspondentFirstName =
    correspondent.userType == "admin"
      ? t(correspondent.firstName)
      : correspondent.firstName;

  if (isCorrespondentMessage) {
    return (
      <div className="chat-sender-details">
        <span className="dot" />
        <h2>{correspondentFirstName}</h2>&nbsp;
        <h3>{format(parseISO(message.sent_at), messageTimeFormat)}</h3>
      </div>
    );
  } else {
    return (
      <div className="chat-sender-details">
        <h3>{format(parseISO(message.sent_at), messageTimeFormat)}</h3>
        &nbsp;
        <h2>{t("chat.message.me")}</h2>
        &nbsp;
        {messageStatusIndicator}
      </div>
    );
  }
};

export default ChatMessageHeader;
