import { IonPage, IonContent, IonList } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ChatMessage from "../components/ChatMessage";
import ChatMessageHeader from "../components/ChatMessageHeader";
import MullanyHeader from "../components/MullanyHeader";
import NoMessagesAdvice from "../components/NoMessagesAdvice";
import { useMessages } from "../contexts/MessagesProvider";
import { useUser } from "../contexts/UserProvider";
import Message from "../entities/Message";
import { Storage } from "@capacitor/storage";

import "../css/MessagesPage.css";

import CreateMessageButton from "../components/CreateMessageButton";
import AttachmentButton from "../components/AttachmentButton";
import CreateMessage from "../components/CreateMessage";

const MessagesPage: React.FC = () => {
  const { correspondentId } = useParams<Record<string, string>>();

  const { userData, getCorrespondentById } = useUser();
  const content = useRef<HTMLIonContentElement>(null);
  const messageContext = useMessages();
  const correspondent = getCorrespondentById(
    correspondentId,
    userData.userDetails
  );
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const scrollToBottom = () => {
    content.current?.scrollToBottom();
  };

  const onTextAreaHeightChanged = (height: number, obj: any) => {
    console.log(height);
    scrollToBottom();
  };

  useEffect(() => {
    //store the messages when page loads for the first time.
    //this will ensure that the dashboard will detect new messages;
    console.log("[] effect");
    const addMessagesToLocalStorage = async () => {
      const messagesToStore = messageContext.currentMessages[correspondentId];
      console.log(`storing ${messagesToStore.length} messages locally`);
      await Storage.set({
        //store messages locally;
        key: `messages_${correspondentId}`,
        value: JSON.stringify(messagesToStore),
      });
    };
    addMessagesToLocalStorage();
  }, []);

  useEffect(() => {
    console.log("scroll effect");
    scrollToBottom();
    setHasScrolled(true);
  }, [messageContext.currentMessages]);

  const newMessageHandler = async (message: Message) => {
    messageContext.postMessageToCorrespondent(message);
  };

  const messageCards = Object.entries(messageContext.currentMessages)
    .filter(([key, messageArray]) => key == correspondentId)
    .map(([key, messages]) => {
      return messages.map((message: Message, idx: number) => {
        const isMyMessage = message.sent_by.id == userData.userDetails.userId;

        return (
          <div key={message.message_id} className={isMyMessage ? "me" : "they"}>
            <ChatMessageHeader
              correspondent={correspondent}
              message={message}
            ></ChatMessageHeader>
            <div className="pointer">
              <i className="bi bi-caret-up-fill"></i>
            </div>
            <ChatMessage message={message} isMyMessage={isMyMessage} />
          </div>
        );
      });
    });

  // don't need CreateMessageButton component for announcements
  const footerButtons =
    correspondent.userID != -2 ? (
      <div className="button-panel">
        <AttachmentButton
          correspondent={correspondent}
          newMessageHandler={newMessageHandler}
        />

        <span></span>

        <CreateMessageButton
          onButtonClicked={() => setIsEditing(true)}
        ></CreateMessageButton>
      </div>
    ) : (
      <span></span>
    );

  const ionContentClassName =
    correspondent.userID! - -2
      ? "chat-container"
      : "chat-container-announcements";

  const hasNoMessages =
    messageContext.currentMessages[correspondentId].length == 0;

  return (
    <IonPage data-testid="bob">
      <MullanyHeader></MullanyHeader>
      <IonContent ref={content} className={ionContentClassName}>
        <>
          {!hasScrolled && (
            <div className="no-messages-advice">
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          )}
          <div className="chat-flexbox">
            <div className="filler" />
            <IonList
              className={hasScrolled ? "chat-list" : "chat-list is-scrolling"}
            >
              {hasNoMessages && <NoMessagesAdvice isEditing={isEditing} />}
              {messageCards}
              {isEditing && (
                <CreateMessage
                  correspondent={correspondent}
                  onHeightChange={onTextAreaHeightChanged}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                  newMessageHandler={newMessageHandler}
                />
              )}
            </IonList>
          </div>
        </>
      </IonContent>
      {!isEditing ? footerButtons : <span />}
    </IonPage>
  );
};

export { MessagesPage };
