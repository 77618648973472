import { IonAlert } from "@ionic/react";
import { t } from "i18next";
import mullanyLogo from "../logos/mullany-logo.svg";

const AttachmentConfirmation: React.FC<{
  showAlert: boolean;
  selectedFile: File | undefined;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
  setShowAlert: (showAlert: boolean) => void;
}> = ({ showAlert, selectedFile, onConfirm, onCancel, setShowAlert }) => {
  return (
    <IonAlert
      isOpen={showAlert}
      cssClass="alert-notifier"
      onDidDismiss={() => setShowAlert(false)}
      header={t("attachment.confirm.header")}
      message={`<div><img src="${mullanyLogo}" class="alert-notifier-logo"></div>${t(
        "attachment.confirm.text"
      )} ${selectedFile?.name}?`}
      buttons={[
        {
          text: t("ok"),
          cssClass: "danger",
          handler: onConfirm,
        },
        {
          text: t("cancel"),
          role: "cancel",
          handler: onCancel,
        },
      ]}
    />
  );
};

export default AttachmentConfirmation;
