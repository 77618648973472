import axios from "axios";
import { t } from "i18next";
import { useState } from "react";

type ErrorStep = {
  error: any;
  step: string;
};

interface ErrorHandler {
  handleError(error: ErrorStep | any): void;
  getErrorHeader(): string;
  getErrorMessage(): string;
}

const useErrorHandler = (): ErrorHandler => {
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleError = (e: ErrorStep | any) => {
    const error = e.error;
    if (e.step === "login") {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 400:
            setErrorHeader(t("error.login.invalid.credentials.header"));
            setErrorMessage(t("error.login.invalid.credentials"));
            break;
        }
      }
    } else if (e.step === "passwordReset") {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 400:
            setErrorHeader(t("error.reset.password.header"));
            setErrorMessage(t("error.reset.password"));
            break;
          case 404:
            setErrorHeader(t("error.reset.password.credentials.header"));
            setErrorMessage(t("error.reset.password.credentials"));
            break;
        }
      }
    } else if (e.step == "registerAccount") {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 404:
            setErrorHeader(t("error.register.invalid.credentials.header"));
            setErrorMessage(t("error.register.invalid.credentials"));
            break;
          case 422:
            setErrorHeader(t("error.register.already.registered.header"));
            setErrorMessage(t("error.register.already.registered"));
            break;
        }
      }
    } else if (e.step === "userDetails") {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 400:
            setErrorHeader(t("error.user.details.header"));
            setErrorMessage(
              t("error.user.details") +
                `  ${t("error.server.message")} : ${error.message}`
            );
            break;
        }
      }
    } else if (e.step === "mentoringSession") {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 404:
            setErrorHeader(t("error.mentoring.session.header"));
            setErrorMessage(
              t("error.mentoring.session") +
                `  ${t("error.server.message")} : ${error.message}`
            );
            break;
        }
      }
    } else if (e.step === "messages") {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 400:
            setErrorHeader(t("error.message.fetch.header"));
            setErrorMessage(t("error.message.fetch"));
            break;
        }
      }
    } else if (e.step == "sendMessage") {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 400:
            setErrorHeader(t("error.message.post.header"));
            setErrorMessage(t("error.message.post"));
            break;
        }
      }
    } else if (e.step == "sendAttachment") {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 400:
            setErrorHeader(t("error.message.attachment.header"));
            setErrorMessage(t("error.message.attachment"));
            break;
        }
      }
    } else if (e.step == "getAttachment") {
      if (axios.isAxiosError(error)) {
        switch (error.response?.status) {
          case 400:
            setErrorHeader(t("error.message.get.attachment.header"));
            setErrorMessage(t("error.message.get.attachment"));
            break;
        }
      }
    }
  };

  const getErrorMessage = () => {
    return errorMessage;
  };
  const getErrorHeader = () => {
    return errorHeader;
  };

  return { handleError, getErrorHeader, getErrorMessage };
};

export default useErrorHandler;
