import { Directory, Filesystem, WriteFileResult } from "@capacitor/filesystem";
import { useRef, useState } from "react";
import { DownloadedAttachment, getAttachment } from "../api-calls/messages-api";
import Attachment from "../entities/Attachment";
import Message from "../entities/Message";
import { FileOpener } from "@ionic-native/file-opener";
import { ErrorNotifier } from "./ErrorNotifier";
import { Capacitor } from "@capacitor/core";
import { IonLoading } from "@ionic/react";
import useErrorHandler from "../hooks/useErrorHandler";

const AttachmentMessage: React.FC<{
  message: Message;
  isMyMessage: boolean;
}> = ({ message, isMyMessage }) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const errorHandler = useErrorHandler();
  const downloadLink = useRef<HTMLAnchorElement>(null);
  const attachment: Attachment = message.attachment as Attachment;

  const getMimeTypeForFilename = (filename: string) => {
    const extension = filename.split(".").pop();

    switch (extension?.toLowerCase()) {
      case "pdf":
        return "application/pdf";
      case "doc":
        return "application/msword";
      case "docx":
        return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      case "txt":
        return "text/plain";
      default:
        return "";
    }
  };

  const downloadForWebPlatform = (
    attachment: Attachment,
    downloadedAttachment: DownloadedAttachment
  ) => {
    if (downloadLink.current) {
      console.log("configuring link for download");
      downloadLink.current.download = attachment.file_name;
      downloadLink.current.href = `data:${getMimeTypeForFilename(
        attachment.file_name
      )};base64,${downloadedAttachment.file}`;
      downloadLink.current.click();
    }
  };
  const downloadForDevice = async (
    attachment: Attachment,
    downloadedAttachment: DownloadedAttachment
  ) => {
    const downloadedFile: WriteFileResult = await Filesystem.writeFile({
      path: attachment.file_name,
      directory: Directory.Documents,
      data: downloadedAttachment.file,
    });

    const mimeType = getMimeTypeForFilename(attachment.file_name);

    await FileOpener.open(downloadedFile.uri, mimeType).catch((Error) => {
      console.log(Error);
    });
  };

  const doDownloadAttachment = (attachment: Attachment) => {
    downloadAttachment(attachment).then(
      (downloadedAttachment: DownloadedAttachment | undefined) => {
        if (!downloadedAttachment) {
          return;
        }
        if (Capacitor.getPlatform() == "web") {
          downloadForWebPlatform(attachment, downloadedAttachment);
        } else {
          downloadForDevice(attachment, downloadedAttachment);
        }
      }
    );
  };

  const downloadAttachment = async (
    attachment: Attachment
  ): Promise<DownloadedAttachment | undefined> => {
    console.log(`downloading ${attachment.file_name} ${attachment.file_token}`);
    setIsLoading(true);
    try {
      return getAttachment(attachment.file_token);
    } catch (error) {
      errorHandler.handleError(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <IonLoading isOpen={isLoading}></IonLoading>;
  }

  if (isError) {
    return <ErrorNotifier isError={true} />;
  }

  if (isMyMessage) {
    return (
      <div className="chat-message">
        <i className={`bi bi-paperclip`}></i>
        &nbsp;{attachment.file_name}
      </div>
    );
  } else {
    return (
      <div className="chat-message">
        <i
          className={`bi bi-file-earmark-arrow-down download-button`}
          onClick={() => doDownloadAttachment(attachment)}
        ></i>
        &nbsp;{attachment.file_name}
        <a ref={downloadLink} />
      </div>
    );
  }
};

export default AttachmentMessage;
