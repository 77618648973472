import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationCy from './locales/cy/translations.json'
import translationEn from './locales/en/translations.json'

console.log("initialising i18n");

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  lng: "en",
  resources: {
    cy: {
      translations: translationCy,
    },
    en: {
      translations: translationEn,
    },
  },
  ns: ["translations"],
  defaultNS: "translations",
});

i18n.languages = ["cy", "en"];

export default i18n;
