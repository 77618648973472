import axios, { AxiosResponse } from "axios";
import config from "../config-env";
import Token from "../entities/Token";

export interface LoginRequest {
  email: string;
  password: string;
}

export interface LoginResponse {
  type: string;
  id: number;
  access_token: Token;
  refresh_token: Token;
}

export interface AccountRegistration {
  email: string;
  dob: string;
  password: string;
}

const login = async (loginRequest: LoginRequest): Promise<LoginResponse> => {
  try {
    const loginResponse: AxiosResponse<LoginResponse> = await axios.post(
      `${config.BASE_URL}/login`,
      loginRequest
    );
    return Promise.resolve(loginResponse.data);
  } catch (error) {
    return Promise.reject({ error: error, step: "login" });
  }
};

const registerAccount = async (
  accountRegistration: AccountRegistration
): Promise<void> => {
  try {
    await axios.post(`${config.BASE_URL}/studentRegister`, accountRegistration);
  } catch (error) {
    return Promise.reject({ error: error, step: "registerAccount" });
  }
};

const passwordReset = async (email: string) => {
  try {
    await axios.post(`${config.BASE_URL}/forgottenPassword`, {
      email: email,
    });
  } catch (error) {
    return Promise.reject({ error: error, step: "passwordReset" });
  }
};

const refreshToken = async (): Promise<LoginResponse> => {
  //TODO check existence of refreshtoken in storage
  try {
    const loginResponse: AxiosResponse<LoginResponse> = await axios.get(
      //bearer token is set in the interceptor
      `${config.BASE_URL}/refresh`
    );
    return Promise.resolve(loginResponse.data);
  } catch (error) {
    return Promise.reject({ error: error, step: "refresh" });
  }
};

const logout = async (): Promise<void> => {
  try {
    await axios.get(`${config.BASE_URL}/logout`);
    return Promise.resolve();
  } catch (error) {
    return Promise.reject({ error: error, step: "logout" });
  }
};

export { login, passwordReset, refreshToken, registerAccount, logout };
