import { Badge } from "@robingenz/capacitor-badge";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import { useState } from "react";
import { Storage } from "@capacitor/storage";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import config from "../config-env";

export type Notification = {
  correspondentId: string;
  isAdmin: boolean;
  isAnnouncement: boolean;
};

interface Notifications {
  checkNotificationPermissions(): void;
  initialiseForDesktop(): void;
  provideNotificationActionListener(
    callback: (notificationActionPerformed: ActionPerformed) => Promise<void>
  ): void;
  providePushNotificationReceived(
    callback: (notification: PushNotificationSchema) => void
  ): void;
  provideDesktopForegroundNotification(callback: (payload: any) => void): void;
}

let notificationCallback: (
  notificationActionPerformed: ActionPerformed
) => void;
let onPushNotficationReceived: (notification: PushNotificationSchema) => void;
let desktopForegroundNotification: (payload: any) => void;

const useNotifications = (): Notifications => {
  const [notificationReceived, setNotificationReceived] =
    useState<boolean>(false);

  const doNotificationRegistration = async () => {
    // Register with Apple / Google to receive push via APNS/FCM
    const { value: deviceId } = await Storage.get({ key: "MULLANY_DEVICE_ID" });
    if (deviceId == null) {
      console.log("registering device for notifications");
      PushNotifications.register();
    }

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", async (token: Token) => {
      console.log("Push registration success. Token is " + token.value);
      await Storage.set({ key: "MULLANY_DEVICE_ID", value: token.value });
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      alert("Error on registration: " + JSON.stringify(error));
    });
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        console.log(`Received notification ${JSON.stringify(notification)}`);
        onPushNotficationReceived(notification);
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notificationActionPerformed: ActionPerformed) => {
        notificationCallback(notificationActionPerformed);
        const isBadgeSupported = await Badge.isSupported();
        if (isBadgeSupported) {
          await Badge.clear();
        }
      }
    );
  };

  const provideNotificationActionListener = (
    callback: (notificationActionPerformed: ActionPerformed) => Promise<void>
  ) => {
    if (notificationCallback == null) {
      notificationCallback = callback;
    }
  };

  const providePushNotificationReceived = (
    callback: (notification: PushNotificationSchema) => void
  ) => {
    if (onPushNotficationReceived == null) {
      onPushNotficationReceived = callback;
    }
  };

  const provideDesktopForegroundNotification = (
    callback: (payload: any) => void
  ) => {
    if (desktopForegroundNotification == null) {
      desktopForegroundNotification = callback;
    }
  };

  const checkNotificationPermissions = () => {
    PushNotifications.checkPermissions().then((res) => {
      if (res.receive !== "granted") {
        PushNotifications.requestPermissions().then((res) => {
          if (res.receive === "denied") {
            console.log("Push Notification permission denied");
          } else {
            console.log("Push Notification permission granted");
            doNotificationRegistration();
          }
        });
      } else {
        doNotificationRegistration();
      }
    });
  };

  const initialiseForDesktop = async () => {
    const firebaseConfig = {
      apiKey: `${config.FIREBASE_API_KEY}`,
      authDomain: "mullany.firebaseapp.com",
      databaseURL: "https://mullany.firebaseio.com",
      projectId: "mullany",
      storageBucket: "mullany.appspot.com",
      messagingSenderId: `${config.FIREBASE_SENDER_ID}`,
      appId: `${config.FIREBASE_APP_ID}`,
      measurementId: `${config.FIREBASE_MEASUREMENT_ID}`,
    };

    const app = initializeApp(firebaseConfig);
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.
    const messaging = getMessaging();

    try {
      const fcmToken = await getToken(messaging, {
        vapidKey: `${config.FIREBASE_VAPID_KEY}`,
      });
      if (fcmToken) {
        console.log(`storing FCM token as device id: ${fcmToken}`);
        await Storage.set({ key: "MULLANY_DEVICE_ID", value: fcmToken });
      } else {
        console.log("Did not get FCM Token");
      }
    } catch (error) {
      console.log(
        `An error occurred while retrieving token. ${JSON.stringify(error)} `
      );
    }

    onMessage(messaging, (payload) => {
      console.log("Message received. ", payload);
      desktopForegroundNotification(payload);
    });
  };

  return {
    checkNotificationPermissions,
    provideNotificationActionListener,
    providePushNotificationReceived,
    initialiseForDesktop,
    provideDesktopForegroundNotification,
  };
};

export default useNotifications;
