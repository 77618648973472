import { IonLoading } from "@ionic/react";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";

import { ErrorNotifier } from "../components/ErrorNotifier";
import { useAuth } from "../contexts/AuthProvider";
import useErrorHandler from "../hooks/useErrorHandler";

const LogoutPage: React.FC = () => {
  const auth = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const errorHandler = useErrorHandler();

  const isMounted = useRef<boolean>(true);

  useEffect(() => {
    const doLogout = async () => {
      try {
        setIsLoading(true);
        await auth.logout();
      } catch (error) {
        errorHandler.handleError(error);
        if (isMounted.current == true) {
          setIsError(true);
        }
      } finally {
        if (isMounted.current == true) {
          setIsLoading(false);
        }
      }
    };
    doLogout();
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (isLoading) {
    return <IonLoading isOpen={isLoading}></IonLoading>;
  }

  return (
    <>
      <div></div>
      <ErrorNotifier
        bodyText={t("logout.error")}
        isError={isError}
      ></ErrorNotifier>
    </>
  );
};

export default LogoutPage;
