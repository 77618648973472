import React, { useState } from "react";
import { createContext } from "react";
import {
  AccountRegistration,
  login,
  LoginRequest,
  LoginResponse,
  logout,
  passwordReset,
  refreshToken,
  registerAccount,
} from "../api-calls/login-api";

import { Storage } from "@capacitor/storage";
import {
  clearTokens,
  hasActiveToken,
  MULLANY_FUND_BEARER_TOKEN,
  MULLANY_FUND_REFRESH_TOKEN,
  storeTokens,
  tokenHasExpired,
} from "../token-management/TokenManagement";
import config from "../config-env";

export interface Authentication {
  isAuthenticated: boolean;
  login(loginRequest: LoginRequest): Promise<void>;
  passwordReset(email: string): Promise<void>;
  registerAccount(account: AccountRegistration): Promise<void>;
  refresh(): Promise<void>;
  invalidCredentialsHandler(failedRequest: any): Promise<void>;
  logout(): Promise<void>;
}

const AuthProvider = ({ children }: { children: any }) => {
  if (process.env.NODE_ENV == "development" && config.CLEAR_TOKENS == "true") {
    console.log("clearing tokens");
    clearTokens();
  }

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const doLogin = async (loginRequest: LoginRequest): Promise<void> => {
    const loginResponse: LoginResponse = await login(loginRequest);
    await storeTokens(loginResponse.access_token, loginResponse.refresh_token);
    const activeToken = await hasActiveToken();
    setIsAuthenticated(activeToken);
  };

  const doLogout = async (): Promise<void> => {
    console.log("doing logout");
    await logout();
    await clearTokens();
    const activeToken = await hasActiveToken();
    setIsAuthenticated(activeToken);
  };

  const doRefreshToken = async (): Promise<void> => {
    const loginResponse: LoginResponse = await refreshToken();
    storeTokens(loginResponse.access_token, loginResponse.refresh_token);
    const activeToken = await hasActiveToken();
    setIsAuthenticated(activeToken);
  };

  const handleInvalidCredentialsResponse = async (
    failedRequest: any
  ): Promise<void> => {
    if (failedRequest.response.data.errors.login) {
      return Promise.reject(failedRequest);
    }
    console.log("Unexpected response (400)");
    if (
      !(await tokenHasExpired(new Date(), MULLANY_FUND_BEARER_TOKEN)) ||
      (await tokenHasExpired(new Date(), MULLANY_FUND_REFRESH_TOKEN))
    ) {
      console.log("clearing tokens..");
      await clearTokens();
      const activeToken = await hasActiveToken();
      setIsAuthenticated(activeToken);
      return Promise.resolve();
    }
    if (
      (await tokenHasExpired(new Date(), MULLANY_FUND_BEARER_TOKEN)) &&
      !(await tokenHasExpired(new Date(), MULLANY_FUND_REFRESH_TOKEN))
    ) {
      console.log("trying refresh and will resubmit request");
      await doRefreshToken();
      const { value: bearerToken } = await Storage.get({
        key: MULLANY_FUND_BEARER_TOKEN,
      });
      failedRequest.response.config.headers["Authorization"] =
        "Bearer " + bearerToken;
      return await Promise.resolve(); //will resubmit the failedRequest
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login: doLogin,
        logout: doLogout,
        passwordReset: passwordReset,
        registerAccount: registerAccount,
        isAuthenticated: isAuthenticated,
        refresh: doRefreshToken,
        invalidCredentialsHandler: handleInvalidCredentialsResponse,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const AuthContext = createContext<Authentication | undefined>(undefined);
const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context == undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
