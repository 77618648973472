import { formatISO, isAfter, isBefore, parseISO } from "date-fns";
import React, { useState } from "react";
import { createContext } from "react";

import {
  fetchMessages,
  partitionAdminMessages,
} from "../api-calls/messages-api";
import Correspondent from "../entities/Correspondent";
import Message from "../entities/Message";
import UserDetails from "../entities/UserDetails";
import { useUser } from "./UserProvider";

interface AllMessages {
  [key: string]: Message[];
}

interface Messages {
  fetchMessages(doNavigation?: boolean): Promise<void>;
  postMessageToCorrespondent(message: Message): void;
  createNewMessageToAppend(
    userDetails: UserDetails,
    correspondent: Correspondent,
    bodyText: string | null,
    filename: string | null
  ): Message;
  currentMessages: AllMessages;
}

const sortMessagesBySentAtAscending = (messages: Message[]): Message[] => {
  messages.sort((a: Message, b: Message) => {
    //if a < b return -ve value
    if (isBefore(parseISO(a.sent_at), parseISO(b.sent_at))) {
      return -1;
    }
    if (isAfter(parseISO(a.sent_at), parseISO(b.sent_at))) {
      return 1;
    }
    return 0;
  });
  return messages;
};

const allocateAdminMessages = (
  messages: Message[][],
  correspondents: Correspondent[]
): AllMessages => {
  //create 2 arrays from the first item in messages array, by filtering for ANNOUNCEMENT type messages;
  const allAdminMessages = messages[0];
  const partitionedMessages = partitionAdminMessages(allAdminMessages);
  //replace messages[0] with partitionedMessages;
  messages.splice(0, 1, partitionedMessages[0]);
  messages.splice(1, 0, partitionedMessages[1]);
  const allMessages: AllMessages = {};
  messages.forEach((messageArray: Message[], idx) => {
    allMessages[correspondents[idx].userID.toString()] =
      sortMessagesBySentAtAscending(messageArray);
  });
  return allMessages;
};

const MessagesProvider = ({
  children,
  initialMessages = { "0": new Array<Message>() },
}: {
  children: any;
  initialMessages?: AllMessages;
}) => {
  const user = useUser();

  const [messages, setMessages] = useState<AllMessages>(initialMessages);

  const getAllMessages = async (): Promise<void> => {
    // get messages for all correspondents from API;
    //TODO investigate whether we can improve UX by only calling once per hour
    //setIsLoading(true);
    const correspondents: Correspondent[] = user.getCorrespondents(
      user.userData.userDetails
    );

    const rawMessages = await fetchMessages(
      correspondents,
      user.userData.userDetails.userId
    );

    const allMessages: AllMessages = allocateAdminMessages(
      rawMessages,
      correspondents
    );

    setMessages(allMessages);
  };

  const postMessageToCorrespondent = (message: Message) => {
    const correspondentId = message.sent_to.id;
    const newCorrespondentMessages = [...messages[correspondentId], message];
    messages[correspondentId] = newCorrespondentMessages;
    const newAllMessages = Object.assign({}, messages);
    setMessages(newAllMessages);
  };

  const createNewMessageToAppend = (
    userDetails: UserDetails,
    correspondent: Correspondent,
    bodyText: string | null,
    filename: string | null
  ): Message => {
    const messageType = bodyText ? "message" : "attachment";
    const attachment =
      messageType == "attachment"
        ? { file_name: filename, file_token: "" }
        : null;

    let message = {
      message_id: 0,
      sent_by: {
        id: userDetails.userId,
        type: userDetails.userType,
      },
      sent_to: { id: correspondent.userID, type: correspondent.userType },
      type: messageType,
      message: bodyText ? bodyText : "",
      moderation: "PENDING",
      active: true,
      message_read: false,
      sent_at: formatISO(new Date()),
      updated_at: "",
    };
    if (attachment) {
      message = Object.assign(message, { attachment: attachment });
    }

    return message;
  };

  return (
    <MessagesContext.Provider
      value={{
        fetchMessages: getAllMessages,
        postMessageToCorrespondent: postMessageToCorrespondent,
        createNewMessageToAppend: createNewMessageToAppend,
        currentMessages: messages,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

const MessagesContext = createContext<Messages | undefined>(undefined);

const useMessages = () => {
  const context = React.useContext(MessagesContext);
  if (context == undefined) {
    throw new Error("useMessages must be used within a MessagesProvider");
  }
  return context;
};
export { MessagesProvider, useMessages };
