import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { Redirect, Route } from "react-router";
import ErrorBoundary from "../components/ErrorBoundary";
import NotificationReceived from "../components/NotificationReceived";
import { MessagesProvider } from "../contexts/MessagesProvider";
import LogoutPage from "../pages/LogoutPage";
import MessagesDashboard from "../pages/MessagesDashboard";
import { MessagesPage } from "../pages/MessagesPage";
import SettingsPage from "../pages/SettingsPage";
import SurveyCompleted from "../pages/SurveyCompleted";
import SurveyRedirect from "../pages/SurveyRedirect";
import AppUrlListener from "./AppUrlListener";

const AuthenticatedApp: React.FC = () => {
  return (
    <IonApp>
      <ErrorBoundary>
        <MessagesProvider>
          <IonRouterOutlet className="authenticated">
            <IonReactRouter>
              <AppUrlListener />
              <Route path="/" exact>
                <MessagesDashboard />
              </Route>
              <Route path="/messagesDashboard" exact>
                <MessagesDashboard />
              </Route>
              <Route path="/messages/:correspondentId" exact>
                <MessagesPage />
              </Route>
              <Route path="/login" exact>
                <Redirect to="/messagesDashboard" />
              </Route>
              <Route exact path="/settings">
                <SettingsPage />
              </Route>
              <Route path="/logout" exact>
                <LogoutPage />
              </Route>
              <Route path="/takeSurvey" exact>
                <SurveyRedirect />
              </Route>
              <Route path="/surveyCompleted/:id" exact>
                <SurveyCompleted />
              </Route>
              <NotificationReceived />
            </IonReactRouter>
          </IonRouterOutlet>
        </MessagesProvider>
      </ErrorBoundary>
    </IonApp>
  );
};

export default AuthenticatedApp;
