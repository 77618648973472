import { IonAlert } from "@ionic/react";

import { useState } from "react";
import { useAuth } from "../contexts/AuthProvider";

import mullanyLogo from "../logos/mullany-logo.svg";

import "../css/ErrorNotifier.css";
import { t } from "i18next";

export type ErrorNotifierProps = {
  headerText?: string;
  bodyText?: string;
  handler?: () => void;
  isError: boolean;
};

export const ErrorNotifier: React.FC<ErrorNotifierProps> = ({
  headerText,
  bodyText,
  handler,
  isError,
}: ErrorNotifierProps) => {
  const [showAlert, setShowAlert] = useState(isError);
  const auth = useAuth();
  const defaultBodyText = t("error.default.message");
  const defaultHeaderText = t("error");
  const defaultHandler = async () => {
    console.log("default handler");
    await auth.logout();
  };
  const alertHandler = handler ? handler : defaultHandler;

  return (
    <IonAlert
      isOpen={showAlert}
      onDidDismiss={() => setShowAlert(false)}
      cssClass="alert-notifier"
      header={headerText ? headerText : defaultHeaderText}
      message={`<div><img src="${mullanyLogo}" class="alert-notifier-logo"></div>${
        bodyText ? bodyText : defaultBodyText
      }`}
      buttons={[
        {
          text: t("ok"),
          role: "cancel",
          cssClass: "danger",
          handler: alertHandler,
        },
      ]}
    />
  );
};
