import { IonApp, IonRouterOutlet } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import LoginPage from "../pages/LoginPage";
import PasswordReset from "../pages/PasswordReset";
import RegisterAccount from "../pages/RegisterAccount";
import SettingsPage from "../pages/SettingsPage";
import AppUrlListener from "./AppUrlListener";

const UnauthenticatedApp: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener />
        <ErrorBoundary>
          <IonRouterOutlet>
            <Switch>
              <Route exact path="/">
                <Redirect to="/login" />
              </Route>
              <Route exact path="/logout">
                <Redirect to="/login" />
              </Route>
              <Route exact path="/login">
                <LoginPage key={Date.now()} />
              </Route>
              <Route exact path="/linkAccount">
                <RegisterAccount />
              </Route>
              <Route exact path="/passwordReset">
                <PasswordReset />
              </Route>
              <Route exact path="/settings">
                <SettingsPage />
              </Route>
              <Route exact path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </IonRouterOutlet>
        </ErrorBoundary>
      </IonReactRouter>
    </IonApp>
  );
};

export default UnauthenticatedApp;
